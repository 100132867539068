<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="app layoutStore">
		<!-- Replicated Nav -->
		<replicated-site-nav
			v-if="showReplicatedSiteBar"
			:replicated="replicatedSiteData" />
		<request-sponsor-modal
			v-model="showStoreModal"
			:redirect-type="redirectType" />
		<offers-modal
			v-model="showOffersModal" />
		<AppHeader
			:type="windowWidth === 'xs' ? 'compact': 'extended'"
			:class="showReplicatedSiteBar ? 'default-nav' : ''"
			:style="showReplicatedSiteBar && setNavbarTop ? 'margin-top: 0px;' : ''"
			fixed>
			<b-link
				:class="{'left-position': (windowWidth === 'sm' || windowWidth === 'md')}"
				class="navbar-brand"
				:to="isAgencySite ? `/${agencyPath}` : '/'">
				<div
					:id="logoIds().navbar"
					class="navbar-brand-full d-none d-sm-block"
					alt="Logo" />
			</b-link>
			<b-navbar-nav class="ml-auto">
				<template v-if="this.$user.auth() || isAgencySite">
					<div :class="['xs', 'sm'].includes(windowWidth) ? 'mx-1' : ''">
						{{ country }}
						<img
							:src="getCountryFlag(country, true)"
							class="ml-1">
					</div>
				</template>
				<country-dropdown
					v-else-if="!this.$user.auth()"
					:class="['xs', 'sm'].includes(windowWidth) ? 'mx-1' : ''" />
				<lang-dropdown :class="['xs', 'sm'].includes(windowWidth) ? 'mx-1' : 'mr-3 ml-4'" />
				<template v-if="!this.$user.auth() && !isAgencySite">
					|
					<button
						v-if="$route.name === 'Store' && !earlyLaunch && showBuyNow && !emergencyMode"
						type="button"
						:class="['xs', 'sm'].includes(windowWidth) ? 'px-2 mx-1' : ''"
						class="btn btn-link d-block text-left"
						@click="becomeCustomer()">
						<a
							:class="['xs', 'sm'].includes(windowWidth) ? '' : 'bolder-text-primary'"
							class="mb-4">
							<b :style="['xs', 'sm'].includes(windowWidth) ? 'font-size:.88em;' : ''">
								{{ translate('buy_now').toUpperCase() }}
							</b>
						</a>
					</button>
					<button
						v-if="(!Object.keys(replicatedSiteData).length || sponsor.can_register) && !emergencyMode"
						type="button"
						:class="['xs', 'sm'].includes(windowWidth) ? 'px-2 mx-1' : ''"
						class="btn btn-link d-block text-left">
						<a
							:class="['xs', 'sm'].includes(windowWidth) ? '' : 'bolder-text-primary'"
							class="mb-4"
							@click="becomeMember()">
							<b :style="['xs', 'sm'].includes(windowWidth) ? 'font-size:.88em;' : ''">
								{{ translate('join_now').toUpperCase() }}
							</b>
						</a>
					</button>
					<div
						v-else
						v-b-tooltip.hover="!emergencyMode ? { placement: 'bottom', html: true, title: translate('cant_sponsor_orders_link', { link: openLiveChat }) } : null"
						:class="['xs', 'sm'].includes(windowWidth) ? 'px-2 mx-1' : ''"
						class="btn btn-link d-block text-left text-muted text-decoration-none">
						<span
							:class="['xs', 'sm'].includes(windowWidth) ? '' : 'bolder-text-secondary'"
							class="mb-4">
							<b
								class="text-muted"
								:style="['xs', 'sm'].includes(windowWidth) ? 'font-size:.88em;' : ''">
								{{ translate('join_now').toUpperCase() }}
							</b>
						</span>
					</div>
					<button
						type="button"
						:class="['xs', 'sm'].includes(windowWidth) ? 'px-2 mx-1' : ''"
						class="btn btn-link d-block text-left">
						<a
							:class="['xs', 'sm'].includes(windowWidth) ? '' : 'bolder-text-primary mr-3'"
							class="mb-4"
							@click="$router.push({ name: 'Login' })">
							<b :style="['xs', 'sm'].includes(windowWidth) ? 'font-size:.88em;' : ''">
								{{ translate('login') }}
							</b>
						</a>
					</button>
				</template>
				<template v-if="$user.auth()">
					<DefaultHeaderDropdownAccnt :show-dashboard="true" />
				</template>
			</b-navbar-nav>
		</AppHeader>
		<AppHeader
			v-if="showPublicStore && !isCart && !isAdminOrCorporate && !earlyLaunch && !$user.details().hide_store && !isDistributorAgreementsView"
			:class="showDark()"
			:style="!isCart && setNavbarTop ? 'margin-top: 55px;' : ''"
			fixed
			class="border-0 store clase-beta">
			<b-navbar-nav
				class="ml-sm-5 mr-auto">
				<button
					v-if="!isAgencySite"
					type="button"
					class="btn btn-link"
					@click="scrollTo('our-products')">
					<a
						class="font-weight-bold text-white">{{ translate('our_products') }}</a>
					<hr
						v-if="activeSection === 'our-products'"
						class="border-white m-0"
						style="width: 20px; border-width: 2px">
				</button>
				<a
					v-if="isAgencySite && ['xs', 'sm'].includes(windowWidth) && !$route.name.includes('Store')"
					@click="$router.push({ name: getRedirectName('Store') })">
					<div class="btn btn-link pointer text-light text-decoration-none px-4 py-0">
						<div>
							<i class="fas fa-chevron-left mr-2" />
							<span class="font-weight-bold">{{ translate('back_to_store') }}</span>
						</div>
					</div>
				</a>
				<!-- <button
					v-if="!['xs', 'sm', 'md'].includes(windowWidth)"
					type="button"
					class="btn btn-link">
					<a
						class="font-weight-bold text-white">{{ translate('contact_us') }}</a>
				</button>
				<button
					v-if="!['xs', 'sm', 'md'].includes(windowWidth)"
					type="button"
					class="btn btn-link">
					<a
						class="font-weight-bold text-white">{{ translate('blog') }}</a>
				</button> -->
			</b-navbar-nav>
			<b-navbar-nav
				ref="cartDropdownButton"
				:style="{ width: !['xs', 'sm'].includes(windowWidth) ? '380px' : '' }"
				class="h-100 mr-md-5">
				<button
					v-if="!emergencyMode"
					type="button"
					class="btn px-4 ml-auto shadow-none"
					@click="cartOpened = !cartOpened">
					<a
						class="font-weight-bold text-white"><i class="fas fa-shopping-cart mr-sm-3" />
						<span
							class="d-none d-sm-inline"
							v-html="itemsInCartLabel" />
						<span class="d-inline d-sm-none ml-2">( {{ items }} )</span>
					</a>
				</button>
			</b-navbar-nav>
			<div
				v-if="!['xs', 'sm', 'md'].includes(windowWidth) && showUpgradeProgress"
				ref="upgradeProgressDesktop"
				class="d-block position-absolute w-100 h-100"
				style="z-index: -2;">
				<div class="row no-gutters justify-content-center">
					<div class="col-auto">
						<div style="width: 35em">
							<div class="row h-100">
								<div class="col">
									<upgrade-progress
										:packages="upgradePackages"
										:current-volume="upgradeVolume"
										:checkout-button="productsHasData"
										card
										style="z-index: 1030" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				v-if="showFloatingBanner"
				ref="upgradeProgressDesktop"
				class="d-block position-absolute w-100"
				style="z-index: -2;"
				:style="{
					'top': activePromoExists ? '55px' : '0px',
				}">
				<div class="row no-gutters justify-content-center">
					<div
						v-if="!isAgencySite"
						class="col-auto">
						<div style="width: 40em;">
							<div class="row h-100 d-flex justify-content-center">
								<div
									class="col"
									:class="['md'].includes(windowWidth) ? 'd-flex justify-content-center' : ''">
									<float-announcement :automatic-discount-percentage="automaticDiscountPercentage" />
								</div>
							</div>
						</div>
					</div>
					<div
						v-else
						class="col-auto">
						<div>
							<div class="row h-100 d-flex justify-content-center">
								<div
									class="col"
									:class="['md'].includes(windowWidth) ? 'd-flex justify-content-center' : ''">
									<agency-floating-announcement
										v-if="agencyDiscountPercentage > 0"
										:discount="agencyDiscountPercentage"
										:seconds="promoSeconds"
										:hours="promoHours"
										:minutes="promoMinutes" />
									<agency-floating-banner
										v-else-if="!!agencyBannerDesktopImage"
										:desktop-image="agencyBannerDesktopImage"
										:mobile-image="agencyBannerMobileImage"
										:seconds="promoSeconds"
										:hours="promoHours"
										:minutes="promoMinutes" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</AppHeader>
		<AppHeader
			v-if="!showCartNavBar && !['xs', 'sm'].includes(windowWidth) && showUpgradeProgress"
			style="margin-top: 55px;"
			fixed
			class="border-0 store bg-transparent clase-alfa">
			<div
				ref="upgradeProgressDesktop"
				class="d-block position-absolute w-100 h-100">
				<div class="row no-gutters justify-content-center">
					<div class="col-auto">
						<div style="width: 35em">
							<div class="row h-100">
								<div class="col">
									<upgrade-progress
										:packages="upgradePackages"
										:current-volume="upgradeVolume"
										:checkout-button="productsHasData"
										card
										style="z-index: 10" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</AppHeader>
		<AppHeader
			v-if="['xs', 'sm', 'md'].includes(windowWidth) && showUpgradeProgress"
			ref="upgradeProgressMobile"
			class="d-block border-top-0 border-right-0 border-left-0 store"
			:style="{
				'margin-top': showCartNavBar ? '110px' : '55px',
				'height': 'auto',
			}"
			fixed>
			<b-navbar-nav class="d-block">
				<div class="row no-gutters h-100 w-100 justify-content-center px-2 py-1">
					<div class="col d-flex align-items-center mx-1">
						<upgrade-progress
							:current-volume="upgradeVolume"
							:packages="upgradePackages"
							:checkout-button="productsHasData"
							compact
							style="z-index: 10" />
					</div>
				</div>
			</b-navbar-nav>
		</AppHeader>
		<!-- BODY & FOOTER -->
		<div class="app-body">
			<template v-if="loadingReplicatedSite">
				<div
					:style="windowWidth === 'xs' ? 'min-height: 85vh' : 'min-height: 100vh'"
					class="row no-gutters w-100 justify-content-center">
					<div class="col-auto d-flex align-items-center">
						<is-loading
							:loading="loadingReplicatedSite"
							:loading-label="translate('loading')"
							:no-data-label="translate('data_not_found')" />
					</div>
				</div>
			</template>
			<template v-else>
				<router-view :key="$route.fullPath" />
			</template>
		</div>
		<!-- FOOTERS -->
		<store-footer v-if="!isAgencySite" />
		<agency-store-footer v-else />
		<!-- MODALS -->
		<product-added-modal
			:loading="systemLoading"
			:cart-items="cartProducts"
			:cart-related-items="cartRelatedProducts"
			:totals="cartTotals"
			:points="cartPoints"
			:show-credits="showCredits"
			:small-text-totals="cartSmallTextTotals"
			:total-volume="totalVolume"
			:total="cartTotal"
			:qty-import="qty"
			:exchange="exchange.is_needed ? exchange.total : ''"
			:apply-free-bottle="applyFreeBottle"
			:free-bottle-message="freeBottleMessage"
			:automatic-discount-percentage="automaticDiscountPercentage"
			:possible-discount-messages="possibleDiscountMessages"
			:free-products-info="freeProductsInfo"
			:promotions="promotions"
			:show-messages="showMessages"
			:discount-detail="discountDetail"
			:show-pre-modal="availablePromo"
			:free-shipping-info="freeShippingInfo"
			@update-quantity="updateQuantity"
			@remove-product="removeProduct"
			@use-points="usePoints"
			@remove-points="removePoints" />
		<cart-sidebar
			:open="cartOpened"
			:loading="systemLoading"
			:cart-items="cartProducts"
			:totals="cartTotals"
			:points="cartPoints"
			:show-credits="showCredits"
			:small-text-totals="cartSmallTextTotals"
			:total-volume="totalVolume"
			:total="cartTotal"
			:qty-import="qty"
			:exchange="exchange.is_needed ? exchange.total : ''"
			:apply-free-bottle="applyFreeBottle"
			:free-bottle-message="freeBottleMessage"
			:automatic-discount-percentage="automaticDiscountPercentage"
			:possible-discount-messages="possibleDiscountMessages"
			:free-products-info="freeProductsInfo"
			:promotions="promotions"
			:show-messages="showMessages"
			:discount-detail="discountDetail"
			:show-pre-modal="availablePromo"
			:free-shipping-info="freeShippingInfo"
			@update-quantity="updateQuantity"
			@remove-product="removeProduct"
			@use-points="usePoints"
			@remove-points="removePoints"
			@open="getData"
			@close="cartOpened = false" />
		<pre-checkout-modal
			:subtotal="creditPromoSubtotal"
			:next-discount="creditPromoDiscount"
			@cancel="$router.push({ name: getRedirectName('Checkout') }).catch(() => {})"
			@confirm="continueShopping" />
	</div>
</template>

<script>
import Vue from 'vue';

// CSS
import { sidebarCssClasses } from '@coreui/vue/src/shared/classes';

// Components
import VCalendar, { setupCalendar } from 'v-calendar';
import { Header as AppHeader } from '@coreui/vue';
import ReplicatedSiteNav from '@/components/AdditionalNav';
import FloatAnnouncement from '@/components/FloatAnnouncement';
import isLoading from '@/components/Loading';
import RequestSponsorModal from '@/components/RequestSponsorModal';
import OffersModal from '@/components/OffersModal';
import UpgradeProgress from '@/components/UpgradeProgress';
import CountryDropdown from '@/views/Layouts/Main/CountryDropdown';
import DefaultHeaderDropdownAccnt from '@/views/Layouts/Main/DefaultHeaderDropdownAccnt';
import LangDropdown from '@/views/Layouts/Main/LangDropdown';

import CartSidebar from './components/CartSidebar';
import ProductAddedModal from './components/ProductAddedModal';
import PreCheckoutModal from './components/PreCheckoutModal';
import AgencyStoreFooter from './components/AgencyStoreFooter';
import StoreFooter from './components/StoreFooter';
import AgencyFloatingAnnouncement from '@/components/AgencyFloatingAnnouncement';
import AgencyFloatingBanner from '@/components/AgencyFloatingBanner';

// Routes
import nav from '@/router/menus/nav';

// Mixins
import AgencySite from '@/mixins/AgencySite';
import CartData from '@/mixins/CartData';
import DocumentTitle from '@/mixins/DocumentTitle';
import EarlyLaunch from '@/mixins/EarlyLaunch';
import PublicStore from '@/mixins/PublicStore';
import WindowSizes from '@/mixins/WindowSizes';

// Settings
import Companies from '@/settings/Company';
import { EXPIRATION_VALUE, EXPIRATION_TIME } from '@/settings/Cookie';
import { ENTITY_MESSAGES_COUNTRIES } from '@/settings/Country';
import { V_CALENDAR_CONFIG, RENEW_MEMBERSHIP_DAYS } from '@/settings/Dates';
import { S3_PATH } from '@/settings/Images';
import { URIS } from '@/settings/LiveChat';
import { ONLY_TUUN_COUNTRIES } from '@/settings/Register';
import {
	LANDING_PAGES, AGENCY_REPLICATED_SITE_NAME,
	AGENCY_DISCOUNT_OPTION_KEY,
} from '@/settings/Replicated';
import {
	admin as adminRoles, superAdmin, customer, distributor, affiliate,
} from '@/settings/Roles';
import { FINAL_STATUSES_CAN_LOGIN } from '@/settings/Statuses';
import { FLOW_ROUTE } from '@/settings/Onboarding';

// Translations
import {
	Store, Products, Grids, Purchase, Cart as CartMessages,
} from '@/translations';

// Utils
import EmergencyMode from '@/util/EmergencyMode';
import Checkout from '@/util/Purchase';
import EventBus from '@/util/eventBus';
import ReplicatedSite from '@/util/ReplicatedSite';

Vue.component('isLoading', isLoading);
Vue.use(VCalendar, V_CALENDAR_CONFIG);

export default {
	name: 'StoreLayout',
	messages: [Store, Products, Grids, Purchase, CartMessages],
	components: {
		AgencyFloatingAnnouncement,
		AgencyFloatingBanner,
		AppHeader,
		CartSidebar,
		CountryDropdown,
		DefaultHeaderDropdownAccnt,
		FloatAnnouncement,
		LangDropdown,
		PreCheckoutModal,
		ProductAddedModal,
		ReplicatedSiteNav,
		RequestSponsorModal,
		OffersModal,
		AgencyStoreFooter,
		StoreFooter,
		UpgradeProgress,
	},
	mixins: [CartData, DocumentTitle, WindowSizes, EarlyLaunch, PublicStore, AgencySite],
	data() {
		return {
			S3_PATH,
			appName: process.env.VUE_APP_NAME,
			poweredBy: process.env.VUE_APP_POWERED,
			activeSection: '',
			replicated: new ReplicatedSite(),
			customer,
			Companies,
			renewMembershipDays: RENEW_MEMBERSHIP_DAYS,
			currentYear: new Date().getFullYear(),
			companyName: process.env.VUE_APP_COMPANY_NAME,
			companyEmail: process.env.VUE_APP_COMPANY_EMAIL,
			isCart: false,
			scrolled: false,
			scrollValue: 0,
			cartOpened: false,
			setNavbarTop: false,
			openLiveChat: URIS.MAXIMIZE_CHAT,
			ENTITY_MESSAGES_COUNTRIES,
			validatorSponsor: new Checkout(),
			sponsorInfo: {},
			agencyReplicatedName: AGENCY_REPLICATED_SITE_NAME,
			ONLY_TUUN_COUNTRIES,
			FINAL_STATUSES_CAN_LOGIN,
			showOffersModal: false,
			onboardingFlowRoutes: FLOW_ROUTE,
			emergencyMode: false,
			emergencyModeChecked: false,
		};
	},
	computed: {
		name() {
			return this.$route.name;
		},
		list() {
			return this.$route.matched.filter((route) => route.name || route.meta.label);
		},
		nav() {
			try {
				let { type } = this.$user.details();
				if (adminRoles.includes(type)) {
					type = superAdmin;
				}
				return this.translateNav(nav[type.toLowerCase()].items);
			} catch (error) {
				return [];
			}
		},
		itemsInCartLabel() {
			let qty = '<i class="fa fa-spinner fa-pulse"></i>';
			if (!this.systemLoading) {
				qty = this.productsHasData ? this.items : 0;
			}
			return this.translate('items_in_cart', { qty });
		},
		items() {
			let items = 0;
			if (this.productsHasData) {
				this.cartData.attributes.products.forEach((item) => {
					items += item.qty;
				});
			}
			return items;
		},
		showCredits() {
			return [distributor, affiliate, customer].includes(this.$user.details().type) && this.hasCredits;
		},
		loadingReplicatedSite() {
			if (this.isAgencySite) {
				return false;
			}
			return this.replicated.data.loading;
		},
		replicatedSiteData() {
			try {
				const { response } = this.replicated.data.response.data;
				return response;
			} catch (error) {
				return {};
			}
		},
		replicatedUsername() {
			return this.$replicated.replicatedId();
		},
		sponsor() {
			try {
				return this.replicatedSiteData.user;
			} catch (e) {
				return {};
			}
		},
		isAdminOrCorporate() {
			return adminRoles.includes(this.$user.details().type);
		},
		isSponsorSet() {
			if (typeof this.sponsor === 'undefined') {
				return false;
			}
			return !!Object.keys(this.sponsor).length;
		},
		isDistributor() {
			return this.$user.details().type === distributor;
		},
		isUpgradeProgressAvailable() {
			return this.isDistributor && Number.isInteger(this.upgradeVolume) && this.hasUpgradePackages;
		},
		totalHeaderHeight() {
			// Init with main navbar height
			let totalHeight = 55;
			// Links an Cart navbar
			if (this.showCartNavBar) {
				totalHeight += 55;
			}
			// Upgrade Progress
			if (this.isUpgradeProgressAvailable) {
				totalHeight += ['xs', 'sm'].includes(this.windowWidth) ? 72 : 32;
			}
			return totalHeight;
		},
		showCartNavBar() {
			if (this.$route.name === 'Cart') return false;
			return true;
		},
		showUpgradeProgress() {
			if (!this.isUpgradeProgressAvailable) return false;
			if (this.$route.name === 'DistributorBusinessAgreements' || this.$route.name === 'PrivacyPolicy') return false;
			if (this.$route.name === 'Store') return this.activeSection === 'our-products';
			return true;
		},
		isDistributorAgreementsView() {
			return this.$route.name === 'DistributorBusinessAgreements' || this.$route.name === 'PrivacyPolicy';
		},
		showReplicatedSiteBar() {
			if (this.emergencyMode) {
				return false;
			}
			const hasReplicatedSiteData = !!Object.keys(this.replicatedSiteData).length;
			let validReplicated = !this.isAgencySite;
			if (hasReplicatedSiteData) {
				validReplicated = !this.isAgencySite && this.replicatedSiteData.replicated_site !== this.agencyReplicatedName;
			}
			return hasReplicatedSiteData && validReplicated;
		},
		showFloatingBanner() {
			if (this.isAgencySite) {
				return this.activePromoExists;
			}
			return this.automaticDiscountPercentage > 0;
		},
		automaticDiscountPercentage() {
			// FIXME: This computed should return 0 when a promotion is active
			try {
				if (!ONLY_TUUN_COUNTRIES.includes(this.getStoredCountry())) {
					return this.replicatedSiteData.user.auto_apply_customer_coupons ?? 0;
				}
				return 0;
			} catch (error) {
				return 0;
			}
		},
		// Credit promo
		availablePromo() {
			try {
				return this.creditDiscount.next_promo.has_next_discounts;
			} catch (e) {
				return false;
			}
		},
		creditPromoSubtotal() {
			try {
				return this.creditDiscount.next_promo.subtotal;
			} catch (e) {
				return '';
			}
		},
		creditPromoDiscount() {
			try {
				return this.creditDiscount.next_promo.discount;
			} catch (e) {
				return '';
			}
		},
		// End credit promo
	},
	watch: {
		setNavbarTop() {
			EventBus.$emit('isReplicatedBarShown', !this.setNavbarTop);
		},
		cartOpened(newVal) {
			const body = document.getElementsByTagName('body')[0];
			if (newVal) {
				body.classList.add('overflow-hidden');
			} else {
				body.classList.remove('overflow-hidden');
			}
		},
		sponsor(newVal) {
			if (typeof newVal !== 'undefined') {
				if (Object.keys(newVal).length) {
					const previousSponsor = this.$cookie.get('replicatedSponsor');
					this.$replicated.siteReplicatedSponsorOn(newVal);
					const newSponsor = this.$cookie.get('replicatedSponsor');
					// Every time the sponsor changes, we need to create a new cart to verify if it needs a new customer group
					if (previousSponsor !== newSponsor) {
						this.createNewCart();
					}
				}
			}
		},
		language(newVal) {
			this.changeDocumentTitle();
			setupCalendar({
				locale: newVal,
			});
		},
		scrollValue(newVal) {
			this.activeSection = '';
			if (document.getElementById('our-products') !== null && newVal >= document.getElementById('our-products').offsetTop - this.totalHeaderHeight) {
				this.activeSection = 'our-products';
			}
		},
		async country(newVal, oldValue) {
			if (!this.emergencyModeChecked) return;
			if (oldValue !== null) {
				await this.renewCart();
			} else if (oldValue === null && !this.cartId) {
				await this.createNewCart();
			}
			// Due to importing two different versions of VSG's script, we need to reload the page when
			// 	a different country is selected to reset external script imports.
			// TODO:
			// 	- Perhaps we can limit this to only detect changes between EU and non-EU countries
			// 	- Remove this after transitioning to single version of credit card payments

			window.location.reload();
		},
		replicatedUsername(newVal) {
			if (!this.emergencyModeChecked) return;
			this.replicated.clear();
			if (!this.$user.auth() && !this.emergencyMode && newVal && (this.isAgencySite || newVal !== this.agencyReplicatedName)) {
				this.replicated.getReplicatedSiteNav(newVal).catch(() => {
					this.exitReplicated();
				});
			} else {
				this.exitReplicated();
			}
		},
		agencyPromoSwitch(newVal) {
			this.getAvailableDiscounts(newVal, this.agencyDiscountCode);
		},
		agencyDiscountCode(newVal) {
			this.getAvailableDiscounts(this.agencyPromoSwitch, newVal);
		},
		$route() {
			this.handleReplicatedSiteRedirects();
			// this.trackPageViewEvent();
		},
		activePromoExists() {
			if (this.activePromoExists) {
				this.startAgencyPromoTimer();
			}
		},
	},
	async created() {
		// Check emergency mode first
		try {
			const emergencyMode = new EmergencyMode();
			const response = await emergencyMode.checkEmergencyMode();
			this.emergencyMode = response.response.emergency_mode;
		} catch (error) {
			console.error('Failed to check emergency mode:', error);
		} finally {
			this.emergencyModeChecked = true;
		}

		if (this.isAgencySite) {
			if (this.country !== 'US') {
				this.country = 'US';
				this.registerCountry = 'US';
			}
		}
		if (this.name === 'Cart') {
			this.isCart = true;
		}
		setupCalendar({ locale: this.language });
		sidebarCssClasses.map((item) => document.body.classList.remove(item));
		window.addEventListener('scroll', this.handleScroll);
		window.addEventListener('keydown', (e) => {
			if (e.key === 'Escape' && this.cartOpened) {
				this.cartOpened = false;
			}
		});
		EventBus.$on('storeViewMounted', () => {
			if (this.$route.query.option === 'our-products' && !this.emergencyMode) {
				this.scrollTo('our-products');
			}
		});
		this.replicated.clear();
		if (!this.$user.auth() && !this.emergencyMode && this.replicatedUsername && (this.isAgencySite || this.replicatedUsername !== this.agencyReplicatedName)) {
			this.replicated.getReplicatedSiteNav(this.replicatedUsername).then(() => {
				this.handleReplicatedSiteRedirects();
				this.validatorSponsor.getSponsorInfo(this.$replicated.replicatedSponsor())
					.then((response) => {
						this.sponsorInfo = response.response;
					})
					.catch((error) => Promise.reject(error));
				// this.trackPageViewEvent();
			}).catch(() => {
				this.exitReplicated();
			});
		} else {
			this.exitReplicated();
		}
		this.handleSourceQueryParam();
	},
	mounted() {
		EventBus.$on('updateLayoutStoreTotals', () => {
			this.getCartRelated();
			if (!this.isDistributorAgreementsView) {
				this.getCartTotals();
			}
		});
		EventBus.$on('scrollTo', (id) => {
			this.scrollTo(id);
		});
		this.setCartCookie(this.$cookie.get('cart_id'), { expired: `${EXPIRATION_VALUE}${EXPIRATION_TIME}` }, false);
		if (!this.isDistributorAgreementsView) {
			this.getData();
		}
		if (this.$user.details().type === distributor) {
			this.getUpgradePackagesInfo();
		}
		if (this.isAgencySite) {
			this.getAvailableDiscounts(this.agencyPromoSwitch, this.agencyDiscountCode);
			setTimeout(() => {
				this.showDefaultOffersModal();
			}, 60000);
		}
	},
	destroyed() {
		window.removeEventListener('scroll', () => {});
	},
	beforeDestroy() {
		EventBus.$off('updateLayoutStoreTotals');
		EventBus.$off('storeViewMounted');
		EventBus.$off('scrollTo');
	},
	methods: {
		async checkEmergencyMode() {
			try {
				const emergencyMode = new EmergencyMode();
				const response = await emergencyMode.checkEmergencyMode();
				this.emergencyMode = response.response.emergency_mode;
			} catch (error) {
				console.error('Failed to check emergency mode:', error);
			}
		},
		exitReplicated() {
			this.$replicated.siteOff();
			this.$replicated.siteReplicatedSponsorOff();
			this.$replicated.siteReplicatedPageOff();
		},
		translateNav(menuItems) {
			const menu = JSON.parse(JSON.stringify(menuItems));
			return menu.filter((item) => {
				if (typeof item.children !== 'undefined') {
					// if it has children, call this function again
					item.children = this.translateNav(item.children);

					if (item.children.length < 1) {
						// after call this function, the item must have children, if not, return null
						return null;
					}
				}

				if (item.children instanceof Array) {
					item.name = this.translate(item.name + process.env.VUE_APP_MENU_POSTFIX);
					return item;
				}

				if (this.hasAccessToPath(item.url)) {
					// string to show in menu
					item.name = this.translate(item.name + process.env.VUE_APP_MENU_POSTFIX);
					return item;
				}
				return null;
			});
		},
		handleScroll() {
			this.setNavbarTop = window.scrollY > 10;
			this.scrolled = window.scrollY > 54;
			this.scrollValue = window.scrollY;
		},
		scrollTo(id) {
			if (id === 'our-products' && this.$route.name !== 'Store') {
				this.$router.push({
					name: this.getRedirectName('Store'),
					query: {
						option: 'our-products',
					},
				});
			}
			this.$nextTick(() => {
				const element = document.getElementById(id);
				if (element) {
					const elementPosition = element.getBoundingClientRect().top;
					const offsetPosition = window.pageYOffset + elementPosition - this.totalHeaderHeight;
					window.scrollTo({
						top: offsetPosition,
						behavior: 'smooth',
					});
				}
			});
		},
		getData() {
			if (this.showPublicStore) {
				if (this.$cookie.get('cart_id') !== null) {
					this.getCartTotals();
				} else if (this.$cookie.get('cart_id') === null) {
					setTimeout(() => {
						this.getCartTotals();
					}, 2000);
				}
			}
		},
		showDark() {
			if (this.windowWidth !== 'xs') {
				if (['ProductDetails', 'FreeEventsDetails', 'DistributorBusinessAgreements', 'PrivacyPolicy', 'PromotionDetails'].includes(this.$route.name) || this.isAgencySite) return 'bg-dark';
				return this.scrolled ? 'bg-dark' : 'bg-transparent';
			}
			return 'bg-dark';
		},
		continueShopping() {
			if (this.$route.name === 'Cart') {
				this.$router.push({ name: this.getRedirectName('Store') }).catch(() => {});
			}
		},
		getCountryFlag(src, isSmall) {
			let path = 'header';
			if (isSmall === true) {
				path = 'select';
			}
			try {
				/* eslint-disable global-require */
				/* eslint-disable import/no-dynamic-require */
				return require(`@/assets/images/common/countries/${path}/${src}.png`);
			} catch (error) {
				return require(`@/assets/images/common/countries/${path}/default.png`);
			}
		},
		handleReplicatedSiteRedirects() {
			// This linting disabler must be removed when the linter is upgraded
			// See: https://github.com/babel/eslint-plugin-babel/pull/163
			// eslint-disable-next-line camelcase
			if (this.replicatedSiteData.user && !this.replicatedSiteData.user.can_sponsor_checkout && this.replicatedSiteData.user.id !== this.$user.details()?.id) {
				return this.$router.push({ name: 'ReplicatedSiteNotAvailable' }).catch(() => {});
			}

			if (this.$route.query.option === 'our-products') {
				this.scrollTo('our-products');
			}

			if (this.$route.query.option) {
				const page = LANDING_PAGES[this.$route.query.option];

				if (page?.route) {
					this.$router.replace(page.route);
				}
			}

			if (this.$route.query.option === 'become-an-affiliate') {
				this.$router.replace({ name: 'BecomeAffiliate' });
			}

			if ((this.$route.query.option === 'checkout') && !this.$user.auth()) {
				localStorage.setItem('custom_cart_uuid', this.$route.query.id);
				this.customCart.getCustomCart().then((response) => {
					// Change the site country to the cart's country.
					const { country } = response.attributes;
					this.country = country;
					this.registerCountry = country;
					const customCartId = response.attributes.cart_id;
					// Then, show the product added modal
					setTimeout(() => {
						// Update the cart id to get correct totals
						this.setCartCookie(customCartId, { expired: `${EXPIRATION_VALUE}${EXPIRATION_TIME.minutes}` }, false);
						EventBus.$emit('updateLayoutStoreTotals', 'custom_cart_items_added');
					}, 1);
				}).catch(() => {
					this.createNewCart();
					localStorage.removeItem('custom_cart_uuid');
				});
			}
			return null;
		},
		showDefaultOffersModal() {
			this.showOffersModal = true;
		},
		// trackPageViewEvent() {
		// 	// Send replicated_site_page_view to Google Analytics 4 only if sponsor is set
		// 	if (this.isSponsorSet) {
		// 		// Try to get current replicated site page ID from URL query param 'option' or from the route name
		// 		let currentLandingPage = Object.entries(LANDING_PAGES).find(([, page]) => page.route?.name === this.$route.name)?.name;
		//
		// 		// Only 'store' can be set manually
		// 		if (typeof currentLandingPage === 'undefined' && this.$route.name === 'Store') {
		// 			currentLandingPage = 'store';
		// 		}
		// 		// Get option
		// 		const option = this.$route.query.option ?? '';
		// 		// Prioritize option over route
		// 		const callback = option
		// 			? ([, link]) => link.option === option
		// 			: ([key]) => key === currentLandingPage;
		// 		// Get list of pages coming from backend
		// 		const linksEntries = Object.entries(this.replicatedSiteData.replicated_links);
		// 		// Get page ID according to search result
		// 		const linkId = linksEntries.find(callback)?.[1].id;
		// 		// Log event to GA4 only if found. Ignore other pages
		// 		if (linkId) {
		// 			this.initializeGtm();
		// 			this.$gtm.trackEvent({
		// 				event: 'replicated_site_page_view',
		// 				sponsor_id: this.sponsor.id,
		// 				replicated_site_page_id: linkId,
		// 			});
		// 		}
		// 	}
		// },
		// initializeGtm() {
		// 	// Set user-scoped sponsor (defined on-platform on Google Tag Manager)
		// 	if (!this.$gtm.enabled()) {
		// 		window.dataLayer = window.dataLayer || [];	// eslint-disable-line no-unused-expressions
		// 		window.dataLayer?.push({ 					// eslint-disable-line no-unused-expressions
		// 			event: 'userData',
		// 			sponsor_id: this.sponsor?.id,
		// 		});
		// 		this.$gtm.enable(true);
		// 	}
		// },
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			if (typeof FINAL_STATUSES_CAN_LOGIN[vm.$user.details().status] !== 'undefined') {
				vm.$router.replace({ name: 'AccountCanceled' });
			} else if (vm.$user.details().force_username_change) {
				vm.$router.replace({ name: 'ForceUsernameChange' });
			} else if (vm.$user.details().remaining_days_terminated === vm.renewMembershipDays && vm.$user.details().type === distributor && (to.name !== 'DistributorBusinessAgreements' && to.name !== 'OfficialDocument')) {
				vm.$router.replace({ name: 'MembershipRenewal' });
			} else if (vm.$user.details().pay_transfer_account_fee && (to.name !== 'DistributorBusinessAgreements' && to.name !== 'PrivacyPolicy')) {
				vm.$router.replace({ name: 'AccountTransferFee' });
			} else if (vm.$user.details().force_redirect_to) {
				const flowCode = vm.$user.details().force_redirect_to;
				const redirectRoute = vm.onboardingFlowRoutes.find((element) => element.flow_code === flowCode);
				if (redirectRoute) {
					vm.$router.replace({ name: redirectRoute.route_name });
				}
			}

			if (to.name.search('Agency') >= 0) { // If is agency site (nootropics or direct)
				if (vm.$replicated === null || vm.$replicated.replicatedId() !== vm.agencyReplicatedName) {
					vm.$replicated.siteOn(vm.agencyReplicatedName);
				}

				const { option } = to.query;
				if (option === AGENCY_DISCOUNT_OPTION_KEY) {
					vm.$replicated.siteSetDiscountsCheck(true);
					vm.$replicated.siteSetDiscountCode(null);
					const { query } = to;
					delete query.option;
					// Format query params
					const params = Object.keys(query).map((key) => `${key}=${query[key]}`).join('&');
					const queryString = Object.keys(query).length > 0 ? `?${params}` : '?';
					// eslint-disable-next-line no-restricted-globals
					history.pushState(null, null, queryString);
				} else if (typeof option !== 'undefined' && option !== null) {
					vm.$replicated.siteSetDiscountsCheck(false);
					vm.$replicated.siteSetDiscountCode(option);
				}
			} else if (vm.$replicated.replicatedId() === vm.agencyReplicatedName) {
				vm.$replicated.siteOff();
				vm.deleteCountdownEndDate();
			}

			if (vm.name === 'Store' && from.name === 'Cart') {
				try {
					vm.getData();
				} catch (error) {
					return {};
				}
			}
			return null;
		});
	},
	beforeRouteLeave(to, from, next) {
		this.cartOpened = false;
		if (to.name === 'Cart') {
			this.isCart = true;
		} else {
			this.isCart = false;
		}
		this.alert.close();
		next();
	},
};
</script>

<style scoped>
	@import url('https://fonts.googleapis.com/css?family=Montserrat');
	p {
		font-family: 'Montserrat', sans-serif !important;
	}
	.layoutStore {
		font-family: 'Montserrat', sans-serif !important;
		font-weight: 600 !important;
	}
	.app-header.navbar.store {
		margin-top: 55px;
		z-index: 1010;
	}
	.default-nav.app-header.navbar[type="extended"] ~ .app-header.navbar.store {
		margin-top: 160px;
		z-index: 1010;
	}
	.default-nav.app-header.navbar[type="compact"] ~ .app-header.navbar.store {
		margin-top: 160px;
		z-index: 1010;
	}
	.app-header.navbar.store {
		margin-top: 55px;
		z-index: 1010;
	}
	.default-nav.app-header.navbar[type="extended"] ~ .app-header.navbar.store {
		margin-top: 125px;
		z-index: 1010;
	}
	.default-nav.app-header.navbar[type="compact"] ~ .app-header.navbar.store {
		margin-top: 125px;
		z-index: 1010;
	}
	.default-nav.app-header.navbar[type="extended"]{
		margin-top: 70px;
	}
	.default-nav.app-header.navbar[type="compact"]{
		margin-top: 70px;
	}
</style>
