export default [
	{
		name: 'MainProfile',
		icon: 'fa fa-user',
		key: 'profile',
	},
	{
		name: 'UpdateMyPassword',
		icon: 'fa fa-key',
		key: 'change_password',
	},
];
