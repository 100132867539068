import { CHECK_EMERGENCY_MODE } from '@/config/endpoint';
import Req from './AxiosRequest';

class EmergencyMode {
	constructor() {
		const axios = new Req();
		this.data = axios;
		this.errors = axios.errors;
	}

	clear() {
		this.data.clear();
	}

	checkEmergencyMode() {
		const { method, endpoint } = CHECK_EMERGENCY_MODE;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default EmergencyMode;
