<!-- eslint-disable vue/no-v-html -->
<template>
	<custom-modal
		:show.sync="showPreCheckoutModal"
		:show-close-button="showCloseButton"
		:pre-title="''"
		:close-text="translate('close')"
		ref-name="preCheckoutModal"
		:title="translate('checkout_modal_title')"
		:modal-size="['xs', 'sm'].includes(windowWidth) ? 'xl' : 'lg-custom-2'"
		@pressClose="close()">
		<div>
			<div
				v-if="continueWithoutPackage"
				class="text-center custom-modal-body px-4"
				v-html="translate('checkout_modal_no_package')" />
			<div
				v-else-if="isRegister"
				class="text-center custom-modal-body px-4"
				v-html="translate('checkout_modal_credit_discount_register')" />
			<div
				v-else
				class="text-center custom-modal-body px-3"
				v-html="translate('checkout_modal_credit_discount_purchase', {'subtotal': subtotal})" />
			<!--<h1
				class="text-center text-success px-3 pt-2"
				v-html="nextDiscount" />-->
			<div
				v-if="!isRegister"
				class="text-center custom-modal-body px-3"
				v-html="translate('checkout_modal_credit_discount_purchase_next')" />
		</div>
		<template #footer>
			<div>
				<div>
					<div class="d-flex justify-content-center text-center">
						<div
							class="bg-primary mt-3 py-2 px-5 pointer font-weight-bold"
							style="min-width: 25%;"
							@click="confirm">
							<span class="text-white">
								{{ isRegister ? translate('checkout_modal_credit_discount_register_button') : translate('checkout_modal_credit_discount_purchase_button') }}
							</span>
						</div>
					</div>
					<div
						class="d-flex justify-content-center text-center mt-1"
						@click="cancel">
						<div
							class="py-2">
							<span
								class="text-muted pointer">
								{{ isRegister ? translate('checkout_modal_credit_discount_register_ignore') : translate('checkout_modal_credit_discount_purchase_ignore') }}
							</span>
						</div>
					</div>
				</div>
			</div>
		</template>
	</custom-modal>
</template>

<script>
import WindowSizes from '@/mixins/WindowSizes';
import {
	Store, FreeEvents, PreCheckoutModal,
} from '@/translations';
import CustomModal from '@/components/CustomModal';
import EventBus from '@/util/eventBus';

export default {
	name: 'PreCheckoutModal',
	messages: [Store, FreeEvents, PreCheckoutModal],
	components: {
		CustomModal,
	},
	mixins: [WindowSizes],
	props: {
		isRegister: {
			type: Boolean,
			default: false,
		},
		subtotal: {
			type: String,
			default: '',
		},
		nextDiscount: {
			type: String,
			default: '',
		},
		showCloseButton: {
			type: Boolean,
			default: true,
		},
		continueWithoutPackage: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			showPreCheckoutModal: false,
		};
	},
	mounted() {
		EventBus.$on('show-pre-checkout-modal', () => {
			this.showPreCheckoutModal = true;
		});
	},
	methods: {
		cancel() {
			this.showPreCheckoutModal = false;
			this.$emit('cancel');
		},
		close() {
			this.showPreCheckoutModal = false;
			this.$emit('close');
		},
		confirm() {
			this.showPreCheckoutModal = false;
			this.$emit('confirm');
		},
	},
};
</script>
