<template>
	<b-card-group
		id="about-us"
		class="aboutUsStore">
		<b-card
			class="border-0 rounded-0 bg-footer mb-0">
			<div
				class="row p-2 mx-auto mt-3 container">
				<div class="col">
					<div class="row">
						<div class="col-md-4 col-lg-5">
							<!-- Company Logo start -->
							<div
								class="row"
								:class="['xs', 'sm'].includes(windowWidth) ? 'mb-2 text-center' : 'pb-2'">
								<div class="col">
									<img
										class="img-fluid footer-width"
										:class="['md'].includes(windowWidth) ? 'w-100' : ''"
										:src="`${S3_PATH}/assets/footer.png`">
								</div>
							</div>
							<!-- Company Logo end -->
							<!-- #LOVIT logo start -->
							<div
								:class="['xs', 'sm'].includes(windowWidth) ? 'text-center mb-0' : 'py-0 mt-0'"
								class="row text-white">
								<div class="col">
									<img
										:class="['xs', 'sm'].includes(windowWidth) ? 'd-none' : ''"
										class="img-fluid"
										:src="`${S3_PATH}/assets/lovit.png`">
								</div>
							</div>
							<!-- #LOVIT logo end -->
							<!-- Accepted payment methods start (desktop only) -->
							<div
								:class="{ 'd-none' : ['sm', 'xs'].includes(windowWidth)}"
								class="row mb-4 py-3 mt-2">
								<div class="col-12">
									<div class="row">
										<div :class="'md' === windowWidth ? 'col-2' : 'col-auto px-2'">
											<img
												:src="require(`@/assets/images/common/cc/logos/american_express.png`)"
												:style="`width: ${'md' === windowWidth ? '31.5px' : '39.4px' } !important; height: auto;`">
										</div>
										<div :class="'md' === windowWidth ? 'col-2' : 'col-auto px-2'">
											<img
												:src="require(`@/assets/images/common/cc/logos/visa.png`)"
												:style="`width: ${'md' === windowWidth ? '31.5px' : '39.4px' } !important; height: auto;`">
										</div>
										<div :class="'md' === windowWidth ? 'col-2' : 'col-auto px-2'">
											<img
												:src="require(`@/assets/images/common/cc/logos/mastercard.png`)"
												:style="`width: ${'md' === windowWidth ? '31.5px' : '39.4px' } !important; height: auto;`">
										</div>
										<div :class="'md' === windowWidth ? 'col-2' : 'col-auto px-2'">
											<img
												:src="require(`@/assets/images/common/cc/logos/discover.png`)"
												:style="`width: ${'md' === windowWidth ? '31.5px' : '39.4px' } !important; height: auto;`">
										</div>
										<div
											:class="'md' === windowWidth ? 'col-2' : 'col-auto px-2'"
											class="pr-2">
											<img
												:src="require(`@/assets/images/common/cc/logos/diners_club.png`)"
												:style="`width: ${'md' === windowWidth ? '31.5px' : '39.4px' } !important; height: auto;`">
										</div>
									</div>
								</div>
							</div>
							<!-- Accepted payment methods end (desktop only) -->
						</div>
						<div class="col-md-8 col-lg-7 d-flex flex-column">
							<div class="row">
								<!-- Site links start -->
								<div class="col-md-6">
									<div
										:class="['xs', 'sm'].includes(windowWidth) ? 'd-none' : ''"
										class="text-white">
										<p class="py-2 px-0 mb-0 headline-small">
											{{ translate('site') }}
										</p>
										<hr
											class="border-white ml-0 mt-0 mb-4"
											style="width: 50px; border-width: 1px">
									</div>
									<div class="row">
										<div class="col-md-6">
											<div
												:class="['xs', 'sm'].includes(windowWidth) ? 'd-none' : ''"
												class="pt-1">
												<a
													class="text-transparency pointer"
													target="_blank"
													:href="$router.resolve({ name: getRedirectName('Store') }).href">
													{{ translate('products') }}
												</a>
											</div>
											<div
												:class="['xs', 'sm'].includes(windowWidth) ? 'd-none' : ''"
												class="pt-1">
												<a
													class="text-transparency pointer"
													target="_blank"
													:href="`${TERMS_AND_CONDITIONS_LINK}`">
													{{ translate('return_and_refund_policies') }}
												</a>
											</div>
										</div>
										<div class="col-md-6">
											<div
												:class="['xs', 'sm'].includes(windowWidth) ? 'd-none' : ''"
												class="pt-1">
												<a
													class="text-transparency pointer"
													target="_blank"
													:href="`${website}/about`">
													{{ translate('about') }}
												</a>
											</div>
											<div
												:class="['xs', 'sm'].includes(windowWidth) ? 'd-none' : ''"
												class="pt-1">
												<a
													class="text-transparency pointer"
													target="_blank"
													:href="`${TERMS_AND_CONDITIONS_LINK}`">
													{{ translate('shipping_and_handling') }}
												</a>
											</div>
											<div
												v-if="isAdminOrCorporate || isDistributor"
												:class="['xs', 'sm'].includes(windowWidth) ? 'text-center' : 'd-none'"
												class="pt-1">
												<a
													class="text-transparency pointer"
													target="_blank"
													:href="policyAndProcedures">
													{{ translate('policies_and_procedures') }}
												</a>
											</div>
											<div
												v-if="isAdminOrCorporate || isDistributor"
												:class="['xs', 'sm'].includes(windowWidth) ? 'd-none' : ''"
												class="pt-1">
												<a
													class="text-transparency pointer"
													target="_blank"
													:href="policyAndProcedures">
													{{ translate('policies_and_procedures_alt') }}
												</a>
											</div>
										</div>
									</div>
								</div>
								<!-- Site links end -->
								<!-- Support info start (desktop only) -->
								<div class="col-md-6 col-lg ml-auto">
									<div
										:class="['xs', 'sm'].includes(windowWidth) ? 'd-none' : ''"
										class="text-white">
										<p class="py-2 px-0 mb-0 headline-small">
											{{ translate('support') }}
										</p>
										<hr
											class="border-white ml-0 mt-0 mb-4"
											style="width: 50px; border-width: 1px">
									</div>
									<div
										:class="['xs', 'sm'].includes(windowWidth) ? 'text-center mt-1' : ''"
										class="text-transparency pt-1">
										<p class="text-white mb-1">
											<i class="fas fa-phone mr-1" />
											<a
												class="text-white mb-1"
												style="text-decoration: none;"
												:href="`tel:${csPhoneLink}`">
												{{ csPhone }}
											</a>
										</p>
										<a
											class="text-transparency mb-1"
											style="text-decoration: none;"
											:href="`mailto:${csEmail}`">
											<p class="mb-1 text-white">
												<i class="fas fa-envelope mr-1 text-white" /> {{ csEmail }}
											</p>
										</a>
										<div
											:class="['xs', 'sm'].includes(windowWidth) ? 'd-flex justify-content-center' : ''">
											<div class="text-white mb-1">
												<div class="d-flex align-items-start">
													<i class="fa fa-map-marker mr-1 mt-1" />
													<p class="pl-2 mb-0">
														{{ companyAddressLine1 }} <br>
														{{ companyAddressLine2 }} <br>
														{{ companyAddressLine3 }}
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<!-- Support info end (desktop only) -->
							</div>
						</div>
					</div>
					<!-- Site links start (mobile only) -->
					<div
						:class="{ 'd-none' : ['md', 'lg', 'xl'].includes(windowWidth)}"
						class="row mt-2 mb-4">
						<div class="col">
							<div class="row justify-content-center">
								<div class="col-12 text-center my-3">
									<a
										class="text-transparency pointer"
										target="_blank"
										:href="$router.resolve({ name: getRedirectName('Store') }).href">
										{{ translate('products') }}
									</a>
								</div>
								<div class="col-12 text-center my-3">
									<a
										class="text-transparency pointer"
										target="_blank"
										:href="`${website}/about`">
										{{ translate('about') }}
									</a>
								</div>
								<div class="col-12 text-center my-3">
									<a
										class="text-transparency pointer"
										:href="`${TERMS_AND_CONDITIONS_LINK}`"
										target="_blank">
										{{ translate('return_and_refund_policies') }}
									</a>
								</div>
								<div class="col-12 text-center my-3">
									<a
										class="text-transparency pointer"
										:href="`${TERMS_AND_CONDITIONS_LINK}`"
										target="_blank">
										{{ translate('shipping_and_handling') }}
									</a>
								</div>
							</div>
						</div>
					</div>
					<!-- Accepted payment methods start (mobile only) -->
					<div
						:class="{ 'd-none' : ['md', 'lg', 'xl'].includes(windowWidth)}"
						class="row mb-4">
						<div class="col">
							<div class="row justify-content-center">
								<div class="col-2">
									<img
										class="mt-2"
										:src="require(`@/assets/images/common/cc/logos/american_express.png`)"
										style="width: 34.5px !important; height: auto;">
								</div>
								<div class="col-2">
									<img
										class="mt-2"
										:src="require(`@/assets/images/common/cc/logos/visa.png`)"
										style="width: 34.5px !important; height: auto;">
								</div>
								<div class="col-2">
									<img
										class="mt-2"
										:src="require(`@/assets/images/common/cc/logos/mastercard.png`)"
										style="width: 34.5px !important; height: auto;">
								</div>
								<div class="col-2">
									<img
										class="mt-2"
										:src="require(`@/assets/images/common/cc/logos/discover.png`)"
										style="width: 34.5px !important; height: auto;">
								</div>
								<div class="col-2">
									<img
										class="mt-2"
										:src="require(`@/assets/images/common/cc/logos/diners_club.png`)"
										style="width: 34.5px !important; height: auto;">
								</div>
							</div>
						</div>
					</div>
					<!-- Accepted payment methods end (mobile only) -->
					<hr
						class="border-transparency"
						style="border-width: 1px">
					<!-- Copyright and disclaimer start -->
					<div
						:class="{ 'text-center' : ['xs', 'sm'].includes(windowWidth)}"
						class="row font-weight-light">
						<div class="col-12 col-md-4">
							<p class="small text-transparency-bright">
								© {{ currentYear }} {{ companyName }} {{ translate('all_right_reserved') }}
							</p>
							<p
								v-if="ENTITY_MESSAGES_COUNTRIES.includes(country)"
								class="small text-transparency-bright">
								<span class="white-transparency-title">{{ translate('payment_entity_title') }}</span>
								<br>{{ translate('payment_entity_text') }}
							</p>
						</div>
						<div class="col-12 col-md-8 captions disclaimer text-transparency-bright">
							{{ translate('disclaimer') }}
						</div>
					</div>
					<!-- Copyright and disclaimer end -->
					<!-- <div class="row text-white font-weight-light text-center mt-4">
						<div class="col-12 captions">
							{{ translate('covid_disclaimer') }}
						</div>
					</div> -->
				</div>
			</div>
		</b-card>
	</b-card-group>
</template>
<script>
import AgencySite from '@/mixins/AgencySite';
import WindowSizes from '@/mixins/WindowSizes';
import { ENTITY_MESSAGES_COUNTRIES } from '@/settings/Country';
import {
	Store, Products, Grids, Purchase,
} from '@/translations';
import {
	COMPANY_ADDRESS_LINE_1,
	COMPANY_ADDRESS_LINE_2,
	COMPANY_ADDRESS_LINE_3,
	DIRECT_SALES_SUPPORT_EMAIL,
	DIRECT_SALES_SUPPORT_NUMBER,
	DIRECT_SALES_SUPPORT_NUMBER_LINK,
	TERMS_AND_CONDITIONS_LINK,
	TERMS_AND_CONDITIONS_REDIRECTION,
} from '@/settings/General';
import { S3_PATH } from '@/settings/Images';
import {
	admin as adminRoles, distributor,
} from '@/settings/Roles';

export default {
	name: 'AgencyStoreFooter',
	messages: [Store, Products, Grids, Purchase],
	mixins: [AgencySite, WindowSizes],
	data() {
		return {
			// Company info
			currentYear: new Date().getFullYear(),
			companyName: process.env.VUE_APP_COMPANY_NAME,
			website: process.env.VUE_APP_WEBSITE,
			companyAddressLine1: COMPANY_ADDRESS_LINE_1,
			companyAddressLine2: COMPANY_ADDRESS_LINE_2,
			companyAddressLine3: COMPANY_ADDRESS_LINE_3,

			// Customer service
			csEmail: DIRECT_SALES_SUPPORT_EMAIL,
			csPhone: DIRECT_SALES_SUPPORT_NUMBER,
			csPhoneLink: DIRECT_SALES_SUPPORT_NUMBER_LINK,

			// Policies
			TERMS_AND_CONDITIONS_LINK,

			// Disclaimer
			ENTITY_MESSAGES_COUNTRIES,

			// Images
			S3_PATH,

			// Terms
			policyAndProcedures: TERMS_AND_CONDITIONS_REDIRECTION,
		};
	},
	computed: {
		isAdminOrCorporate() {
			return adminRoles.includes(this.$user.details().type);
		},
		isDistributor() {
			return this.$user.details().type === distributor;
		},
	},
};
</script>
<style scoped>
	@import url('https://fonts.googleapis.com/css?family=Montserrat');
	p {
		font-family: 'Montserrat', sans-serif !important;
	}
	.aboutUsStore {
		font-family: 'Montserrat', sans-serif !important;
		font-weight: 600 !important;
	}
	.white-transparency-title {
		color: #f1f1f1;
	}
	.text-white {
		color: #6c6c6c7a;
	}
	.text-transparency {
		color: #ffffffad;
	}
	.text-transparency-bright {
		color: #ffffffc0 !important;
	}
	.disclaimer{
		color:#828282;
	}
	.border-transparency {
		border-color: #6c6c6cbf;
	}
	.bg-footer {
		background-color:#2b2b2b;
	}
	.footer-width {
		max-width: 265px;
	}
</style>
