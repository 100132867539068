<template>
	<div
		v-if="show"
		class="sticky-offer-banner"
		:style="{
			'min-width': isSmallView ? '375px' : '780px',
			'background': `url('${isSmallView ? mobileImage : desktopImage}')`,
		}">
		<div class="w-100 d-flex justify-content-end">
			<div>
				<div class="clock-container text-success mr-2 mt-1">
					<i class="fa fa-clock-o clock-icon mr-1" />
					<div class="clock-time">
						{{ minutes }}:{{ seconds }}
					</div>
				</div>
				<div class="time-remaining mr-1">
					{{ translate('left_to_buy') }}
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import { Dashboard as DashboardTranslations } from '@/translations';

export default {
	name: 'AgencyFloatingBanner',
	messages: [DashboardTranslations],
	mixins: [WindowSizes],
	props: {
		show: {
			type: Boolean,
			default: true,
		},
		desktopImage: {
			type: String,
			default: '',
		},
		mobileImage: {
			type: String,
			default: '',
		},
		day: {
			type: String,
			default: '00',
		},
		hours: {
			type: String,
			default: '00',
		},
		minutes: {
			type: String,
			default: '00',
		},
		seconds: {
			type: String,
			default: '00',
		},
	},
	computed: {
		isSmallView() {
			try {
				return ['xs', 'sm'].includes(this.windowWidth);
			} catch (e) {
				return false;
			}
		},
	},
};
</script>
<style scoped>
.sticky-offer-banner {
	height: 60px;
	display: flex;
}

.clock-container {
	display: flex;
	align-items: center;
	font-family: Arial, sans-serif;
}

.clock-icon {
	font-size: 24px;
}

.clock-time {
	font-size: 20px;
	font-weight: bold;
}

.time-remaining {
	font-size: 14px;
	color: #888;
	line-height: 10px;
}

</style>
