<!-- eslint-disable vue/no-v-html -->
<template>
	<div
		v-if="show"
		class="sticky-offer-banner"
		:style="{
			'min-width': isSmallView ? '375px' : '780px',
		}">
		<div
			:style="{
				width: isSmallView ? '50%' : '40%',
			}"
			class="left-side px-2 bg-success">
			<p
				:class="{
					'h2 mt-2': !isSmallView,
					'h5 mt-2': isSmallView,
				}"
				class="text-white font-weight-bold">
				{{ translate('automatic_discount_title', { percent: discount }) }}
			</p>
		</div>
		<div
			class="right-side"
			:class="{
				'justify-content-between': !isSmallView,
				'justify-content-end': isSmallView,
			}"
			:style="{
				width: isSmallView ? '50%' : '60%',
			}">
			<div
				v-if="!isSmallView"
				class="d-flex justify-content-center align-items-center"
				:style="{
					width: '75%'
				}">
				<p
					class="mt-2 h6 text-success font-weight-bold text-center align-items-center"
					v-html="translate('automatic_discount_message')" />
			</div>
			<div>
				<div class="clock-container text-success mr-2 mt-1">
					<i class="fa fa-clock-o clock-icon mr-1" />
					<div class="clock-time">
						{{ minutes }}:{{ seconds }}
					</div>
				</div>
				<div class="time-remaining mr-1">
					{{ translate('left_to_buy') }}
				</div>
			</div>
		</div>
		<div
			class=" "
			:class="isSmallView ? 'pb-0 pt-0' : 'pb-1 pt-1'" />
	</div>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import { Dashboard as DashboardTranslations } from '@/translations';

export default {
	name: 'AgencyFloatingAnnouncement',
	messages: [DashboardTranslations],
	mixins: [WindowSizes],
	props: {
		show: {
			type: Boolean,
			default: true,
		},
		discount: {
			type: Number,
			default: 0,
		},
		day: {
			type: String,
			default: '00',
		},
		hours: {
			type: String,
			default: '00',
		},
		minutes: {
			type: String,
			default: '00',
		},
		seconds: {
			type: String,
			default: '00',
		},
	},
	computed: {
		isSmallView() {
			try {
				return ['xs', 'sm'].includes(this.windowWidth);
			} catch (e) {
				return false;
			}
		},
	},
};
</script>
<style scoped>
.sticky-offer-banner {
	height: 60px;
	display: flex;
}
.sticky-offer-banner .left-side {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	border-radius: 0px 0px 0px 5px;
}
.sticky-offer-banner .left-side::after {
	content: "";
	border-right: 20px solid white;
	border-top: 30px solid transparent;
	border-bottom: 30px solid transparent;
	position: absolute;
	right: -1px;
}
.sticky-offer-banner .right-side {
	background-color: white;
	border-top: #DF703D 1px solid;
	border-bottom: #DF703D 1px solid;
	border-right: #DF703D 1px solid;
	border-bottom-right-radius: 5px;
	display: flex;
}

.clock-container {
	display: flex;
	align-items: center;
	font-family: Arial, sans-serif;
}

.clock-icon {
	font-size: 24px;
}

.clock-time {
	font-size: 20px;
	font-weight: bold;
}

.time-remaining {
	font-size: 14px;
	color: #888;
	line-height: 10px;
}

</style>
