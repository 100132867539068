export default {
	items: [
		{
			name: 'dashboard',
			url: '/home',
			icon: 'fa fa-columns',
		},
		{
			name: 'go_to_store',
			url: '/store',
			icon: 'fas fa-shopping-cart',
		},
		{
			name: 'go_to_physical_store',
			url: '/pickup/store',
			icon: 'fas fa-shopping-bag',
		},
		{
			name: 'orders',
			url: '/orders/',
			icon: 'fa fa-list-alt',
			children: [
				{
					name: 'orderHistory',
					url: '/orders/all',
					icon: 'fa fa-bars',
				},
				{
					name: 'downloads',
					url: '/orders/downloads',
					icon: 'fas fa-download',
				},
			],
		},
		{
			name: 'agencyPromotions',
			url: '/agency-promotions',
			icon: 'fas fa-tag',
		},
		{
			name: 'reports',
			url: '/reports/',
			icon: 'fa fa-clipboard',
			children: [
				{
					name: 'agencySalesDetails',
					url: '/reports/sales-report',
					icon: 'fa fa-file',
				},
				{
					name: 'sponsoredCustomers',
					url: '/my-network/sponsored-customers',
					icon: 'fa fa-user-tag',
				},
			],
		},
		{
			name: 'support',
			url: '/support',
			icon: 'fas fa-life-ring',
		},
	],
};
