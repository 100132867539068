import admin from './admin';
import corporate from './corporate';
import distributor from './distributor';
import customer from './customer';
import affiliate from './affiliate';
import preDistributor from './pre_distributor';
import agency from './agency';

export default {
	admin, corporate, distributor, customer, affiliate, pre_distributor: preDistributor, agency,
};
