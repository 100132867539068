var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show)?_c('div',{staticClass:"sticky-offer-banner",style:({
		'min-width': _vm.isSmallView ? '375px' : '780px',
	})},[_c('div',{staticClass:"left-side px-2 bg-success",style:({
			width: _vm.isSmallView ? '50%' : '40%',
		})},[_c('p',{staticClass:"text-white font-weight-bold",class:{
				'h2 mt-2': !_vm.isSmallView,
				'h5 mt-2': _vm.isSmallView,
			}},[_vm._v(" "+_vm._s(_vm.translate('automatic_discount_title', { percent: _vm.discount }))+" ")])]),_c('div',{staticClass:"right-side",class:{
			'justify-content-between': !_vm.isSmallView,
			'justify-content-end': _vm.isSmallView,
		},style:({
			width: _vm.isSmallView ? '50%' : '60%',
		})},[(!_vm.isSmallView)?_c('div',{staticClass:"d-flex justify-content-center align-items-center",style:({
				width: '75%'
			})},[_c('p',{staticClass:"mt-2 h6 text-success font-weight-bold text-center align-items-center",domProps:{"innerHTML":_vm._s(_vm.translate('automatic_discount_message'))}})]):_vm._e(),_c('div',[_c('div',{staticClass:"clock-container text-success mr-2 mt-1"},[_c('i',{staticClass:"fa fa-clock-o clock-icon mr-1"}),_c('div',{staticClass:"clock-time"},[_vm._v(" "+_vm._s(_vm.minutes)+":"+_vm._s(_vm.seconds)+" ")])]),_c('div',{staticClass:"time-remaining mr-1"},[_vm._v(" "+_vm._s(_vm.translate('left_to_buy'))+" ")])])]),_c('div',{staticClass:" ",class:_vm.isSmallView ? 'pb-0 pt-0' : 'pb-1 pt-1'})]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }