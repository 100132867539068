export default {
	items: [
		{
			name: 'dashboard',
			url: '/home',
			icon: 'fa fa-columns',
		},
		{
			name: 'store',
			url: '/upgrade/',
			icon: 'fas fa-shopping-cart',
		},
		// {
		// 	name: 'upgrade',
		// 	url: '/upgrade',
		// 	icon: 'far fa-lightbulb',
		// },
		{
			name: 'orders',
			url: '/orders/',
			icon: 'fa fa-list-alt',
			children: [
				{
					name: 'orderHistory',
					url: '/orders/all',
					icon: 'fa fa-bars',
				},
				{
					name: 'downloads',
					url: '/orders/downloads',
					icon: 'fas fa-download',
				},
			],
		},
		{
			name: 'myNetwork',
			url: '/my-network/',
			icon: 'fa fa-share-alt',
			children: [
				{
					name: 'binaryTree',
					url: '/my-network/binary-tree',
					icon: 'fas fa-network-wired',
				},
				{
					name: 'unilevelTree',
					url: '/my-network/unilevel-tree',
					icon: 'fas fa-level-down-alt',
				},
				{
					name: 'sponsoredDistributors',
					url: '/my-network/sponsored-distributors',
					icon: 'fa fa-user',
				},
				// {
				// 	name: 'sponsoredCustomers',
				// 	url: '/my-network/sponsored-customers',
				// 	icon: 'fa fa-user-tag',
				// },
				{
					name: 'downlinesInfo',
					url: '/my-network/downlines-info',
					icon: 'fa fa-user',
				},
			],
		},
		// {
		// 	name: 'wallets',
		// 	url: '/wallets/',
		// 	icon: 'fa fa-wallet',
		// 	children: [
		// 		{
		// 			name: 'commissionWallet',
		// 			url: '/wallets/transactions/commission',
		// 			icon: 'fa fa-file',
		// 		},
		// 		{
		// 			name: 'depositWallet',
		// 			url: '/wallets/transactions/deposit',
		// 			icon: 'fa fa-file',
		// 		},
		// 	],
		// },
		// {
		// 	name: 'autoshipInformation',
		// 	url: '/autoship-information',
		// 	icon: 'fas fa-truck',
		// },
		// {
		// 	name: 'reports',
		// 	url: '/reports/',
		// 	icon: 'fa fa-clipboard',
		// 	children: [
		// 		{
		// 			name: 'bv',
		// 			url: '/reports/bv',
		// 			icon: 'fa fa-file',
		// 		},
		// 		{
		// 			name: 'commissions',
		// 			url: '/reports/commissions',
		// 			icon: 'fa fa-file',
		// 		},
		// 	],
		// },
		{
			name: 'mediaLibrary',
			url: '/media-library',
			icon: 'fas fa-newspaper',
		},
		{
			name: 'faq',
			url: '/faq',
			icon: 'fas fa-question',
		},
		{
			name: 'support',
			url: '/support',
			icon: 'fas fa-life-ring',
		},
	],
};
