<template>
	<AppHeaderDropdown
		:class="windowWidth === 'xs' ? 'dropdown-offset-right': ''"
		right
		no-caret>
		<template slot="header">
			<span>{{ language.toUpperCase() }} <img
				src="@/assets/images/lang/map.png"
				alt="lang"></span>
		</template>
		<template slot="dropdown">
			<b-dropdown-header
				tag="div"
				class="text-center">
				<strong>{{ translate('language') }}</strong>
			</b-dropdown-header>
			<b-dropdown-item
				v-for="(item, key) in langs"
				:key="key"
				@click="language = item.attributes.code">
				{{ item.attributes.name }} <i :class="language === item.attributes.code ? 'fa fa-check pull-right text-primary ml-1' : ''" />
			</b-dropdown-item>
		</template>
	</AppHeaderDropdown>
</template>

<script>
import { HeaderDropdown as AppHeaderDropdown } from '@coreui/vue';
import Lang from '@/util/Language';
import WindowSizes from '@/mixins/WindowSizes';
import User from '@/util/User';

export default {
	name: 'LangDropdown',
	components: {
		AppHeaderDropdown,
	},
	mixins: [WindowSizes],
	data() {
		return {
			languageUpdate: new User(),
			languages: new Lang(),
		};
	},
	computed: {
		langs() {
			try {
				const { data } = this.languages.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		currentLang() {
			try {
				const response = this.langs.find((item) => {
					if (item.attributes.code === this.language) {
						return item.attributes.name;
					}
					return null;
				});
				return response.attributes.code.toUpperCase();
			} catch (error) {
				return this.translate('loading');
			}
		},
	},
	watch: {
		language() {
			if (this.$user.auth()) {
				this.languageUpdate.updateLanguage({ language: this.language });
			}
		},
	},
	beforeMount() {
		this.languages.getLanguages();
	},
};
</script>

<style>
	.dropdown-offset-right .dropdown-menu-right{
		left: -15px !important;
		width: min-content;
	}
	.dropdown-menu.dropdown-menu-right.show{
		width: min-content;
		left: -5px !important;
	}
</style>
