export default [
	{
		name: 'MainProfile',
		icon: 'fa fa-user',
		key: 'profile',
	},
	{
		name: 'SoftRegister',
		icon: 'fas fa-user-plus',
		key: 'new_member',
	},
	// {
	// 	name: 'TwoFactorAuthentication',
	// 	icon: 'fa fa-shield-alt',
	// 	key: 'two_factor_authentication',
	// },
	{
		name: 'UpdateMyPassword',
		icon: 'fa fa-key',
		key: 'change_password',
	},
];
