export default [
	{
		name: 'MainProfile',
		icon: 'fa fa-user',
		key: 'profile',
	},
	// {
	// 	name: 'TwoFactorAuthentication',
	// 	icon: 'fa fa-shield-alt',
	// 	key: 'two_factor_authentication',
	// },
	{
		name: 'UpdateMyPassword',
		icon: 'fa fa-key',
		key: 'change_password',
	},
	{
		name: 'BecomeDistributor',
		icon: 'fa fa-user-plus',
		key: 'become_distributor',
	},
	{
		name: 'UpgradeCustomerToAffiliate',
		icon: 'fa fa-lightbulb',
		key: 'upgrade_to_affiliate',
	},
];
