<!-- eslint-disable vue/no-v-html -->
<template>
	<custom-modal
		:show.sync="showStoreModal"
		title=""
		:modal-size="['xs', 'sm'].includes(windowWidth) ? 'xl' : 'lg-custom-3'"
		:show-edge="false"
		ref-name="offersModal"
		:show-close-button="false"
		background-color="#FFFFFF"
		background-image=""
		@cancel="() => {}">
		<template #header>
			<div class="d-flex justify-content-center align-items-center my-2 mx-3">
				<div>
					<img
						:src="require(`@/assets/images/themes/${theme}/modals/offers.svg`)"
						alt=""
						width="100px"
						class="image-outside-modal">
				</div>
				<div class="separator">
					<h3 class="m-0 pt-4 mt-4 pb-4">
						{{ translate('offers_title') }}
					</h3>
				</div>
			</div>
			<div class="d-flex justify-content-center align-items-center text-center my-2 mx-3">
				<h1 class="m-0 mt-3">
					{{ translate('offers_subtitle') }}
				</h1>
			</div>
			<div class="d-flex justify-content-center align-items-center my-2 mx-3 text-center">
				<h4
					v-if="!['xs', 'sm'].includes(windowWidth)"
					v-html="translate('offers_content')" />
				<p
					v-else
					v-html="translate('offers_content')" />
			</div>
		</template>
		<div
			class="pt-4 justify-content-center align-items-center text-center row-content"
			style="margin-top: -40px; margin-bottom: 30px">
			<h4
				class="h4-bold"
				v-html="translate('savings_title')" />
			<p v-html="translate('savings_content')" />
			<div
				class="form-group mx-auto text-left"
				style="max-width: 400px;">
				<label class="text-muted">{{ translate('email') }}:</label>
				<input-text
					:setter-value="form.email"
					:required="true"
					type="text"
					@dataChanged="form.email = $event" />
				<div
					class="text-danger"
					v-html="form.error" />
			</div>
			<b-button
				variant="primary"
				:disabled="isLoading"
				@click="saveEmail()">
				{{ translate('button') }}
			</b-button>
			<p
				class="text-small text-muted m-3 pb-3 pointer"
				@click="cancelAction">
				{{ translate('cancel') }}
			</p>
		</div>
		<template #footer>
			<div />
		</template>
	</custom-modal>
</template>
<script>
import CustomModal from '@/components/CustomModal/index.vue';
import InputText from '@/components/InputText/index.vue';
import WindowSizes from '@/mixins/WindowSizes';
import { OffersModal } from '@/translations';
import ReplicatedSite from '@/util/ReplicatedSite';

export default {
	name: 'OffersModal',
	components: { InputText, CustomModal },
	messages: [OffersModal],
	mixins: [WindowSizes],
	props: {
		value: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			replicated: new ReplicatedSite(),
			form: {
				email: '',
				error: '',
			},
			theme: process.env.VUE_APP_THEME,
			alert: new this.$Alert(),
		};
	},
	computed: {
		showStoreModal: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		isLoading() {
			return !!this.replicated.data.loading;
		},
	},
	watch: {
		'form.email': {
			handler() {
				this.form.error = '';
			},
		},
		showStoreModal(show) {
			if (show) {
				this.form.email = '';
			}
		},
	},
	methods: {
		saveEmail() {
			const payload = { email: this.form.email };
			this.replicated.savePromoEmail(payload)
				.then(() => {
					this.showStoreModal = false;
					this.alert.toast('success', this.translate('success'));
				})
				.catch(() => {
					// eslint-disable-next-line prefer-destructuring
					this.form.error = this.replicated.errors.errors.email[0];
				});
		},
		cancelAction() {
			this.showStoreModal = false;
		},
	},
};
</script>
<style scoped>
h1 {
	font-family: MontserratExtraBold, Montserrat, sans-serif !important;
	color: #df703d;
}

h2, h3 {
	font-family: MontserratExtraBold, Montserrat, sans-serif !important;
}

h4 {
	font-family: Montserrat, sans-serif !important;
	font-size: 20px;
}

.h4-bold{
	font-family: MontserratExtraBold, Montserrat, sans-serif !important;
}

p, button, label {
	font-family: Montserrat, sans-serif !important;
}

.separator {
	border-bottom: 1px solid #ccc;
}

.form-group /deep/ input {
	font-family: Montserrat, sans-serif !important;
	border: none;
	border-bottom: 1px solid #bfbfbf;
	border-radius: 0;

	/* Style when focused */
	&:focus {
		border-bottom-color: orange;
		outline: none;
		box-shadow: 0 0 0 0;
	}
}

.image-outside-modal {
	position: absolute;
	top: -20%; /* Ajusta este valor para controlar cuánto de la imagen estará fuera del modal */
	left: 50%;
	transform: translateX(-50%);
	z-index: 10; /* Asegúrate de que la imagen esté sobre el modal */
}

.row-content {
	background-color: #f8f8f8 !important;
	padding: 0px 50px;
}
</style>
