<!-- eslint-disable vue/no-v-html -->
<template>
	<div
		v-if="show"
		class="card mb-0 p-0"
		:style="{
			border: '1px solid #DF703D',
			position: isSmallView ? 'fixed' : '',
			bottom: isSmallView ? 0 : '',
			margin: isSmallView ? '5px' : '',
		}">
		<div
			class="card-body m-0 pl-0 pr-0 mb-2 mt-2"
			:class="isSmallView ? 'pb-0 pt-0' : 'pb-1 pt-1'">
			<div class="m-0">
				<div
					class="row no-gutters m-0"
					:class="isSmallView ? '' : 'mt-1'">
					<template v-if="!isSmallView">
						<div class="col-5 text-center">
							<h1
								class="discount-text pb-0"
								v-html="translate('automatic_discount_sticky_amount', { percentage: automaticDiscountPercentage })" />
						</div>
						<div class="col-2 text-center">
							<i
								class="fas fa-3x fa-user-tag pb-1 mb-2 pr-3"
								style="color: #DF703D;" />
						</div>
						<div class="col-5">
							<span
								class="rotated-text pl-2"
								style="max-width: 220px;"
								v-html="translate('automatic_discount_sticky', { size: 'large' })" />
						</div>
					</template>
					<template v-else>
						<div class="col-12 text-center m-0 p-0">
							<h1
								class="discount-text pb-0 mb-0"
								style="font-size: revert;"
								v-html="translate('automatic_discount_sticky_amount', { percentage: automaticDiscountPercentage })" />
							<i
								class="fas fa-2x fa-user-tag ml-3"
								style="color: #DF703D;" />
						</div>
						<div class="col-12 text-center m-0 p-0 text-small">
							<span
								class="rotated-text"
								v-html="translate('automatic_discount_sticky', { size: 'medium' })" />
						</div>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';

export default {
	name: 'FloatAnnouncement',
	mixins: [WindowSizes],
	props: {
		show: {
			type: Boolean,
			default: true,
		},
		automaticDiscountPercentage: {
			type: [Number, String],
			default: 0,
		},
	},
	computed: {
		isSmallView() {
			try {
				return ['xs', 'sm', 'md'].includes(this.windowWidth);
			} catch (e) {
				return false;
			}
		},
	},
};
</script>
<style scoped>
.discount-text {
	font-weight: bold;
	color: #DF703D;
	display: inline-block;
}
.rotated-text {
	display: inline-block;
	color: #55585A;
	top: 27%;
	left: 0;
	transform-origin: top left;
}
</style>
