<!-- eslint-disable vue/no-v-html -->
<template>
	<b-sidebar
		v-model="visible"
		bg-variant="light"
		backdrop
		backdrop-variant="dark"
		shadow
		no-header
		right
		width="380px"
		@change="handleVisibilityChange">
		<div class="row no-gutters">
			<div class="col">
				<div class="card rounded-0 border-0">
					<div class="card-body">
						<div class="row mb-3">
							<div class="col pb-2 border-bottom position-relative">
								<button
									type="button"
									class="close float-right"
									aria-label="Close"
									@click="visible = false">
									<span
										class="h3"
										aria-hidden="true">
										&times;
									</span>
								</button>
								<h4 class="text-center text-uppercase font-weight-bold">
									{{ translate('shopping_cart') }}
								</h4>
								<h5
									v-if="totalVolume && !isAgencySite"
									class="text-center font-weight-normal">
									{{ translate('total_volume') }}: {{ totalVolume }}
								</h5>
							</div>
						</div>
						<div class="row mt-3">
							<div
								v-if="cartItemsHasData && totalsHasData && !loading"
								class="col">
								<div class="font-weight-normal">
									<template v-for="(specificTotal, key) in totals">
										<div :key="key">
											<div class="row pb-2">
												<div class="col-7">
													<div
														:class="{
															'text-medium': smallTextTotals.includes(key),
														}"
														class="text-muted">
														{{ key == 'shipping' ? translate('shipping_and_handling') : translate(key) }}
														<discount-detail
															v-if="key === 'discount'"
															:id="`discount-widget`"
															:discount-detail="discountDetail" />
													</div>
												</div>
												<div class="col-5">
													<div
														:class="{
															'text-discount': ['discount', 'points'].includes(key),
															'text-muted': !['discount', 'points'].includes(key),
															'text-medium': smallTextTotals.includes(key),
														}"
														class="text-right">
														{{ specificTotal }}
													</div>
												</div>
											</div>
										</div>
									</template>
									<div class="row">
										<div class="col">
											<div class="border-top">
												<div class="row pt-2">
													<div class="col-4">
														<div class="h5 font-weight-bold mb-0">
															{{ translate('total') }}
														</div>
													</div>
													<div class="col-8">
														<div class="h5 font-weight-bold text-right mb-0">
															{{ total }}
														</div>
													</div>
												</div>
												<div
													v-if="!!exchange"
													class="row">
													<div class="col h6 exchange-text text-right">
														{{ exchange }}*
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<free-shipping-progress
									v-if="freeShippingInfo"
									class="mt-3"
									:status="freeShippingInfo.status"
									:current-amount="freeShippingInfo.current_amount"
									:remaining-amount="freeShippingInfo.remaining_amount"
									:free-shipping-at="freeShippingInfo.free_shipping_at" />
								<cart-points
									v-if="showCredits"
									:points="points"
									alert-class="secondary mt-3"
									@use-points="$emit('use-points')"
									@remove-points="$emit('remove-points')"
									@update-split-wallet="$emit('update-split-wallet')" />
								<div
									v-if="applyFreeBottle"
									class="row mt-2">
									<div class="col d-flex justify-content-center">
										<div class="alert alert-green w-100 pl-1">
											<ul class="fa-ul mb-0">
												<li><span class="fa-li"><i class="fas fa-gift mr-2" /></span> <span v-html="translate(freeBottleMessage)" /></li>
											</ul>
										</div>
									</div>
								</div>
								<div class="row no-gutters">
									<div class="col-6 pr-1">
										<button
											type="button"
											class="btn btn-secondary btn-block btn-lg my-2"
											@click="$router.push({ name: getRedirectName('Cart')})">
											<small>{{ translate('view_cart') }}</small>
										</button>
									</div>
									<div class="col-6 pl-1">
										<button
											type="button"
											class="btn btn-primary btn-block btn-lg my-2"
											@click="handleCheckoutRedirect(showPreModal)">
											<small>{{ translate('checkout') }}</small>
										</button>
									</div>
								</div>
								<div
									v-if="!!exchange"
									class="row">
									<div class="col exchange-text mt-1 text-small">
										{{ translate('exchange_disclaimer') }}
									</div>
								</div>
								<div
									v-if="automaticDiscountPercentage > 0"
									class="row mt-2">
									<div class="col d-flex justify-content-center">
										<div class="alert alert-green w-100 pl-1">
											<ul class="fa-ul mb-0">
												<li><span class="fa-li"><i class="fas fa-gift mr-2" /></span> <span v-html="translate('apply_automatic_discount_message', { percentage: automaticDiscountPercentage })" /></li>
											</ul>
										</div>
									</div>
								</div>
								<!-- 30-day money back guarantee -->
								<div
									v-if="isAgencySite"
									class="row">
									<div class="col">
										<div class="mt-2 mb-3">
											<p class="h5 py-0 my-0 text-center">
												<i class="fa fa-check-circle-o mr-1" />
												{{ translate('thirty_day_money_back_guarantee') }}
											</p>
										</div>
									</div>
								</div>
								<div
									v-if="showMessages"
									class="mt-2">
									<possible-discounts
										:possible-discount-messages="possibleDiscountMessages"
										:free-products-info="freeProductsInfo"
										:promotions="promotions" />
								</div>
							</div>
							<div
								v-if="!cartItemsHasData || !totalsHasData || loading"
								class="col">
								<is-loading
									:loading-label="translate('loading')"
									:no-data-label="translate('empty_cart')"
									:loading="loading"
									:has-data="!!cartItemsHasData && !!totalsHasData" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			v-if="cartItemsHasData && !loading"
			class="row no-gutters">
			<div class="col">
				<ul class="list-group">
					<li
						v-for="(product, key) in cartItems"
						:key="key"
						class="list-group-item px-0 border-left-0 border-right-0 rounded-0">
						<div class="row no-gutters">
							<div class="col-4">
								<img
									:class="['xs', 'sm', 'md'].includes(windowWidth) ? 'mw-100 align-middle' : ''"
									class="d-block m-auto"
									:src="product.image"
									alt="">
							</div>
							<div class="col-8">
								<div class="row no-gutters">
									<div class="col-11">
										<div class="h6 font-weight-bold text-primary">
											{{ translate(product.code_name) }}
											<span
												v-if="product.replaceable"
												v-b-tooltip.hover
												:title="translate('pick_flavors')"
												class="badge badge-green pointer">
												{{ translate('replaceable') }}
												<i class="fas fa-info-circle" />
											</span>
										</div>
										<div class="row">
											<div class="col-4 mb-1">
												<div class="font-weight-bold">
													{{ translate('subtotal') }}:
												</div>
											</div>
											<div class="col-8">
												<div class="text-right font-weight-light">
													{{ product.sub_total }}
												</div>
											</div>
										</div>
										<div
											v-if="!blackList.includes(product.sku)"
											class="row mb-1">
											<div class="col">
												<quantity-select
													:init-qty="qty[product.item_id]"
													:select-limit="10"
													@change="updateQuantity($event, product)" />
											</div>
										</div>
										<div
											v-else
											class="row">
											<div class="col-4 mb-1">
												<div class="font-weight-bold">
													{{ translate('qty_abbrev') }}:
												</div>
											</div>
											<div class="col-8">
												<div class="text-right font-weight-light">
													{{ qty[product.item_id] }}
												</div>
											</div>
										</div>
										<div class="row mt-1 mb-1">
											<div class="col-3">
												<a
													class="pointer font-weight-light text-small text-blue"
													@click="removeProduct($event, product)">
													{{ translate('remove') }}
												</a>
											</div>
											<div class="col-9 text-right px-0">
												<span
													v-if="product.not_individual_purchase"
													v-b-tooltip.hover
													:title="translate('not_available_individually_message')"
													class="text-success text-small font-weight-light pointer">
													{{ translate('not_available_individually') }}
													<i class="fas fa-info-circle" />
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</b-sidebar>
</template>
<script>
import CartPoints from '@/components/Cart/CartPoints';
import CartDisplay from '@/mixins/CartDisplay';
import AgencySite from '@/mixins/AgencySite';
import DiscountDetail from '@/components/Cart/DiscountDetail';
import FreeShippingProgress from '@/components/FreeShippingProgress/index.vue';

export default {
	name: 'CartSidebar',
	components: {
		FreeShippingProgress,
		CartPoints,
		DiscountDetail,
	},
	mixins: [CartDisplay, AgencySite],
	props: {
		open: {
			type: Boolean,
			default: false,
		},
		showCredits: {
			type: Boolean,
			default: false,
		},
		showPreModal: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			visible: false,
		};
	},
	watch: {
		open(newVal) {
			this.visible = newVal;
		},
	},
	methods: {
		handleVisibilityChange(visible) {
			if (!visible) {
				this.$emit('close');
			} else {
				this.$emit('open');
			}
		},
	},
};
</script>
<style scoped>
	.text-blue {
		color: #13637f !important;
	}
</style>
