<template>
	<AppHeaderDropdown
		right
		no-caret>
		<template slot="header">
			<img
				:alt="$user.details().email"
				:src="profilePhoto"
				class="img-avatar mr-2">
			<span>{{ ['xs'].includes(windowWidth) ? shortenedUsername : $user.details().username }}</span>
			<i class="fa fa-chevron-down ml-1 mr-3" />
		</template>
		<template slot="dropdown">
			<b-dropdown-header
				tag="div"
				class="text-center">
				<strong>{{ translate('settings') }}</strong>
			</b-dropdown-header>
			<b-dropdown-item
				v-if="showDashboard && !showOnlyLogin"
				@click="$router.push({ name: 'Home' });">
				<i class="fa fa-columns" /> {{ translate('dashboard') }}
			</b-dropdown-item>
			<template v-if="!showOnlyLogin">
				<template v-for="(item, key) in menu">
					<b-dropdown-item
						v-if="!item.disabled"
						:key="key"
						v-b-tooltip.hover="{ placement: 'bottom', boundary: 'viewport', title: item.tooltip }"
						@click="redirect(item.name)">
						<i :class="item.icon" /> {{ translate(item.key) }}
					</b-dropdown-item>
					<b-dropdown-item
						v-else
						:key="key"
						v-b-tooltip.hover="{ placement: 'bottom', boundary: 'viewport', title: item.tooltip }"
						disabled
						class="disabled-dropdown-item">
						<i :class="item.icon" /> {{ translate(item.key) }}
					</b-dropdown-item>
				</template>
			</template>
			<b-dropdown-item
				@click="logout">
				<i class="fa fa-lock" /> {{ translate('logout') }}
			</b-dropdown-item>
		</template>
	</AppHeaderDropdown>
</template>

<script>
import { HeaderDropdown as AppHeaderDropdown } from '@coreui/vue';
import WindowSizes from '@/mixins/WindowSizes';
import dropdownNav from '@/router/menus/headerNav';
import { PROFILE_PICTURE_FIELD_NAME } from '@/settings/Profile';
import { distributor } from '@/settings/Roles';
import { STATUSES } from '@/settings/Statuses';
import { Auth, Profile, Network } from '@/translations';
import registerStore from '@/views/Register/store';
import { POINTS_TO_ACTIVATE } from '@/settings/Points';

export default {
	name: 'DefaultHeaderDropdownAccnt',
	messages: [Auth, Network, Profile],
	components: {
		AppHeaderDropdown,
	},
	mixins: [WindowSizes],
	props: {
		showDashboard: {
			type: Boolean,
			default: false,
		},
		showOnlyLogin: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			pointsToActivate: POINTS_TO_ACTIVATE,
		};
	},
	computed: {
		menu() {
			try {
				const { type, status } = this.$user.details();
				const menu = dropdownNav[type.toLowerCase()];

				// Disable 'Register New Member' for non-active distributors
				if (type === distributor && status !== STATUSES.active) {
					const registerIndex = menu.findIndex((menuItem) => menuItem.name === 'Register');
					menu[registerIndex].disabled = true;
					menu[registerIndex].tooltip = this.translate('your_account_is_inactive_no_link', { points: this.pointsToActivate });
				} else {
					// menu[registerIndex] requires being reset manually
					const registerIndex = menu.findIndex((menuItem) => menuItem.name === 'Register');
					if (registerIndex !== -1) {
						delete menu[registerIndex].disabled;
						delete menu[registerIndex].tooltip;
					}
				}

				return menu;
			} catch (error) {
				return [];
			}
		},
		profilePhoto() {
			if (typeof this.$user.details()[PROFILE_PICTURE_FIELD_NAME] === 'string' && this.$user.details()[PROFILE_PICTURE_FIELD_NAME].trim() !== '') {
				return this.$user.details()[PROFILE_PICTURE_FIELD_NAME];
			}
			return require('@/assets/images/common/profile.png'); // eslint-disable-line global-require
		},
		shortenedUsername() {
			const string = this.$user.details().username;
			const maxLen = 6;
			return (string.length > maxLen) ? `${string.substr(0, maxLen)}...` : string;
		},
	},
	mounted() {
		this.registerCountry = this.country;
		this.setRegisterCountry(this.country);
		this.$forceUpdate();
	},
	methods: {
		logout() {
			this.$user.logout().then(() => this.$router.push({ name: 'Login' }));
		},
		redirect(name) {
			if (name === 'Register') {
				this.$store.dispatch('position/removeRegister');
				registerStore.dispatch('removePosition');
				this.registerCountry = this.country;
				this.setRegisterPositionValues({});
				this.setRegisterCountry(this.country);
				this.$forceUpdate();
			}
			this.$router.push({ name }).catch(() => {});
		},
	},

};
</script>

<style>
.dropdown-menu .dropdown-item {
	border-bottom: 1px solid #c8ced3 !important;
	border-top-right-radius: 0px !important;
	border-top-left-radius: 0px !important;
	border-bottom-right-radius: 0px !important;
	border-bottom-left-radius: 0px !important;
}

.dropdown-menu li:last-child .dropdown-item {
	border-bottom: 0px !important;
	border-bottom-right-radius: calc(0.25rem - 1px) !important;
	border-bottom-left-radius: calc(0.25rem - 1px) !important;
}

.disabled-dropdown-item > * {
	cursor: default !important;
	color: #73818f !important;
}
</style>
