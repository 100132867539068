<!-- eslint-disable vue/no-v-html -->
<template>
	<AppHeader
		:type="windowWidth === 'xs' ? 'compact': 'extended'"
		fixed
		class="bg-dark replicated-navbar">
		<template v-if="windowWidth !== 'xs'">
			<b-navbar-nav class="w-100 h-100">
				<div class="row w-100 h-100 mx-auto mr-2">
					<div class="col-1 text-center text-white p-2 h-100">
						<img
							:src="getItem('profile_photo') ? getItem('profile_photo') : defaultProfilePhoto"
							alt="profile_picture"
							width="50"
							class="img-avatar">
					</div>
					<div class="col-6 text-white text-left h-100 d-flex align-items-center">
						<div class="w-100">
							<div
								v-if="windowWidth !== 'xs'"
								class="mx-auto text-secondary ellipsis">
								{{ getItem('name') }}
							</div>
							<div
								v-if="windowWidth !== 'xs'"
								:type="windowWidth === 'xl' && getItem('biography') ? 'extended' : 'compact'"
								class="ellipsis text-secondary">
								{{ getItem('biography') ? getItem('biography') : '' }}
							</div>
						</div>
						<div
							v-if="windowWidth === 'xs'"
							:class="contactKeys.length && socialKeys.length ? 'first-nav-item-width': ''">
							<div class="d-block btn text-white text-center pl-0">
								<div class="font-weight-bold">
									{{ getItem('name') }}
								</div>
							</div>
							<div class="row">
								<div
									v-if="contactKeys.length"
									class="col">
									<a
										v-if="getItem('mobile')"
										:href="getSpecialValue('mobile')"
										:class="socialKeys.length ? 'text-left' : 'text-center'"
										class="d-block btn btn-link text-white pointer pl-0"><i class="fas fa-phone mr-2" /><span>{{ getItem('mobile') }}</span></a>
									<a
										v-if="getItem('email')"
										:class="socialKeys.length ? 'text-left' : 'text-center'"
										class="d-block btn btn-link text-white pointer pl-0"
										@click.prevent="mailTo('email')"><i class="fas fa-envelope mr-2" /><span>{{ translate('send_email') }}</span></a>
									<template v-if="register && !isCustomerOrAffiliate">
										<a
											v-if="canRegisterWithThisSponsor"
											:class="socialKeys.length ? 'text-left' : 'text-center'"
											class="d-block btn btn-link text-white pointer pl-0"
											@click="$router.push({ name: 'Register' })">
											<i class="fas fa-user-plus mr-2" />
											<span>{{ translate('register') }}</span>
										</a>
										<span
											v-else
											v-b-tooltip.hover="{ placement: 'bottom', html: true, title: translate('cant_sponsor_orders_link', { link: openLiveChat }) }"
											:class="socialKeys.length ? 'text-left' : 'text-center'"
											class="d-block btn btn-link text-muted pl-0">
											<i class="fas fa-user-plus mr-2" />
											<span>{{ translate('register') }}</span>
										</span>
									</template>
								</div>
								<div
									v-if="socialKeys.length"
									class="col">
									<template v-for="item in socialNetwork">
										<a
											v-if="getItem(item)"
											:key="item"
											:href="getSpecialValue(item)"
											:class="contactKeys.length ? 'text-left' : 'text-center'"
											target="_blank"
											class="d-block btn btn-link text-white pointer pl-0">
											<template v-if="typeof specialIcons[item] !== 'undefined'">
												<img
													:src="require(`@/assets/images/common/icons/${specialIcons[item].type}/${specialIcons[item].value}`)"
													:alt="item"
													width="20px"
													height="20px">
											</template>
											<i
												v-else
												:class="`fa-${item}`"
												class="fab mr-2" /><span>{{ translate(item) }}</span></a>
									</template>
								</div>
							</div>
						</div>
					</div>
					<div
						v-if="contactKeys.length"
						class="col-5 text-white pull-right h-100">
						<div
							v-if="['xl', 'lg'].includes(windowWidth)"
							class="col-12 p-0 d-flex align-items-center h-100">
							<div class="row ml-auto mr-0 mx-3">
								<a
									v-if="getItem('mobile')"
									:href="getSpecialValue('mobile')">
									<div
										:class="['lg'].includes(windowWidth) ? 'mr-1 p-2' : 'mr-3'"
										class="btn btn-link text-white pointer">
										<div>
											<i class="fas fa-2x fa-phone" />
										</div>
									</div>
								</a>
								<a
									v-if="getItem('email')"
									@click.prevent="mailTo('email')">
									<div
										:class="['lg'].includes(windowWidth) ? 'mr-1 p-2' : 'mr-3'"
										class="btn btn-link text-white pointer">
										<div>
											<i class="fas fa-2x fa-envelope" />
										</div>
									</div>
								</a>
								<template v-if="register && !isCustomerOrAffiliate">
									<a
										v-if="canRegisterWithThisSponsor"
										@click="$router.push({ name: 'Register' })">
										<div
											:class="['lg'].includes(windowWidth) ? 'mr-1 p-2' : 'mr-3'"
											class="btn btn-link text-white pointer">
											<div>
												<i class="fas fa-user-plus" />
											</div>
										</div>
									</a>
									<span v-else>
										<div
											v-b-tooltip.hover="{ placement: 'bottom', html: true, title: translate('cant_sponsor_orders_link', { link: openLiveChat }) }"
											:class="['lg'].includes(windowWidth) ? 'mr-1 p-2' : 'mr-3'"
											class="btn btn-link text-muted">
											<div>
												<i class="fas fa-user-plus" />
											</div>
										</div>
									</span>
								</template>
								<template v-for="item in socialNetwork">
									<a
										v-if="getItem(item)"
										:key="item"
										:href="getSpecialValue(item)"
										target="_blank">
										<div
											:class="['lg'].includes(windowWidth) ? 'mr-1 p-2' : 'mr-3'"
											class="btn btn-link text-white pointer">
											<div>
												<template v-if="typeof specialIcons[item] !== 'undefined'">
													<img
														:src="require(`@/assets/images/common/icons/${specialIcons[item].type}/${specialIcons[item].value}`)"
														:alt="item"
														width="20px"
														height="20px">
												</template>
												<i
													v-else
													:class="`fa-${item}`"
													class="fab mr-2" />
											</div>
										</div>
									</a>
								</template>
							</div>
							<b-button
								class="m-0 p-2 text-light"
								variant="link"
								@click="exitReplicated()">
								<i class="fas fa-times" />
							</b-button>
						</div>
						<div
							v-if="['md', 'sm'].includes(windowWidth) && hasSocial"
							class="col-12 p-0 d-flex align-items-center h-100">
							<div class="row ml-auto mr-0 justify-content-end">
								<a
									v-if="getItem('mobile')"
									:href="getSpecialValue('mobile')">
									<div class="btn btn-link text-white pointer px-0 py-1">
										<div><i class="fas fa-2x fa-phone mr-3" /></div>
									</div>
								</a>
								<a
									v-if="getItem('email')"
									@click.prevent="mailTo('email')">
									<div class="btn btn-link text-white pointer px-0 py-1">
										<div><i class="fas fa-2x fa-envelope mr-3" /></div>
									</div>
								</a>
								<template v-if="register && !isCustomerOrAffiliate">
									<a
										v-if="canRegisterWithThisSponsor"
										@click="$router.push({ name: 'Register' })">
										<div class="btn btn-link text-white pointer px-0 py-1">
											<div><i class="fas fa-user-plus mr-3" /></div>
										</div>
									</a>
									<span v-else>
										<div
											v-b-tooltip.hover="{ placement: 'bottom', html: true, title: translate('cant_sponsor_orders_link', { link: openLiveChat }) }"
											class="btn btn-link text-muted px-0 py-1">
											<div><i class="fas fa-user-plus mr-3" /></div>
										</div>
									</span>
								</template>
								<template v-for="item in socialNetwork">
									<a
										v-if="getItem(item)"
										:key="item"
										:href="getSpecialValue(item)"
										target="_blank">
										<div class="btn btn-link text-white pointer px-0 py-1">
											<div>
												<template v-if="typeof specialIcons[item] !== 'undefined'">
													<img
														:src="require(`@/assets/images/common/icons/${specialIcons[item].type}/${specialIcons[item].value}`)"
														:alt="item"
														width="20px"
														height="20px">
												</template>
												<i
													v-else
													:class="`fa-${item}`"
													class="fab mr-2" />
											</div>
										</div>
									</a>
								</template>
							</div>
							<b-button
								class="m-0 p-2 text-light"
								variant="link"
								@click="exitReplicated()">
								<i class="fas fa-times" />
							</b-button>
						</div>
						<div
							v-if="['md', 'sm'].includes(windowWidth) && !hasSocial"
							class="col-12 p-0 d-flex align-items-center h-100">
							<div class="row ml-auto mr-0 justify-content-end">
								<a
									v-if="getItem('mobile')"
									:href="getSpecialValue('mobile')">
									<div class="btn btn-link text-white pointer px-0 py-2">
										<div><i class="fas fa-2x fa-phone mr-4" /></div>
									</div>
								</a>
								<a
									v-if="getItem('email')"
									@click.prevent="mailTo('email')">
									<div class="btn btn-link text-white pointer px-0 py-2">
										<div><i class="fas fa-2x fa-envelope mr-4" /></div>
									</div>
								</a>
								<template v-if="register && !isCustomerOrAffiliate">
									<a
										v-if="canRegisterWithThisSponsor"
										@click="$router.push({ name: 'Register' })">
										<div class="btn btn-link text-white pointer px-0 py-2">
											<div><i class="fas fa-user-plus mr-4" /></div>
										</div>
									</a>
									<span v-else>
										<div
											v-b-tooltip.hover="{ placement: 'bottom', html: true, title: translate('cant_sponsor_orders_link', { link: openLiveChat }) }"
											class="btn btn-link text-muted px-0 py-2">
											<div><i class="fas fa-user-plus mr-4" /></div>
										</div>
									</span>
								</template>
								<b-button
									class="m-0 p-2 text-light"
									variant="link"
									@click="exitReplicated()">
									<i class="fas fa-times" />
								</b-button>
							</div>
						</div>
					</div>
				</div>
			</b-navbar-nav>
		</template>
		<template v-if="windowWidth === 'xs'">
			<b-navbar-nav class="w-100 h-50 pt-1">
				<div class="row w-100 mx-auto">
					<div class="col-3 text-right pt-1">
						<img
							:src="getItem('profile_photo') ? getItem('profile_photo') : defaultProfilePhoto"
							alt="profile_picture"
							width="30"
							class="img-avatar">
					</div>
					<div class="col-6 text-center mt-2">
						<div class="text-secondary ellipsis">
							{{ getItem('name') }}
						</div>
					</div>
					<div class="col-3 text-left pt-1">
						<b-button
							class="text-light"
							variant="link"
							@click="exitReplicated()">
							<i class="fas fa-times" />
						</b-button>
					</div>
				</div>
			</b-navbar-nav>
			<b-navbar-nav class="w-100 h-50 pt-1">
				<div class="row w-100 mx-auto h-100">
					<div
						v-if="getItem('mobile')"
						:class="hasSocial ? 'p-0 m-0' : ''"
						class="col">
						<a
							:href="getSpecialValue('mobile')">
							<div
								:class="hasSocial ? 'p-1 m-0' : ''"
								class="btn btn-link text-white pointer">
								<div>
									<i
										class="fas fa-phone"
										style="font-size: 1.2em;" />
								</div>
							</div>
						</a>
					</div>
					<div
						v-if="getItem('email')"
						:class="hasSocial ? 'p-0 m-0' : ''"
						class="col">
						<a
							@click.prevent="mailTo('email')">
							<div
								:class="hasSocial ? 'p-1 m-0' : ''"
								class="btn btn-link text-white pointer">
								<div>
									<i
										class="fas fa-envelope"
										style="font-size: 1.2em;" />
								</div>
							</div>
						</a>
					</div>
					<div
						v-if="register && !isCustomerOrAffiliate"
						:class="hasSocial ? 'p-0 m-0' : ''"
						class="col">
						<a
							v-if="canRegisterWithThisSponsor"
							@click="$router.push({ name: 'Register' })">
							<div
								:class="hasSocial ? 'p-1 m-0' : ''"
								class="btn btn-link text-white pointer">
								<div>
									<i
										class="fas fa-user-plus"
										style="font-size: 1.2em;" />
								</div>
							</div>
						</a>
						<span v-else>
							<div
								v-b-tooltip.hover="{ placement: 'bottom', html: true, title: translate('cant_sponsor_orders_link', { link: openLiveChat }) }"
								:class="hasSocial ? 'p-1 m-0' : ''"
								class="btn btn-link text-muted">
								<div>
									<i
										class="fas fa-user-plus"
										style="font-size: 1.2em;" />
								</div>
							</div>
						</span>
					</div>
					<template v-if="hasSocial">
						<template v-for="item in socialNetwork">
							<div
								v-if="getItem(item)"
								:key="item"
								:class="hasSocial ? 'p-0 m-0' : ''"
								class="col">
								<a
									:href="getSpecialValue(item)"
									target="_blank">
									<div
										:class="hasSocial ? 'p-1 m-0' : ''"
										class="btn btn-link text-white pointer">
										<div>
											<template v-if="typeof specialIcons[item] !== 'undefined'">
												<img
													:src="require(`@/assets/images/common/icons/${specialIcons[item].type}/${specialIcons[item].value}`)"
													:alt="item"
													width="20px"
													height="20px">
											</template>
											<i
												v-else
												:class="`fa-${item}`"
												class="fab mr-2" />
										</div>
									</div>
								</a>
							</div>
						</template>
					</template>
				</div>
			</b-navbar-nav>
		</template>
	</AppHeader>
</template>
<script>
import { Header as AppHeader } from '@coreui/vue';
import WindowSizes from '@/mixins/WindowSizes';
import { URIS } from '@/settings/LiveChat';
import {
	SOCIAL_NETWORK, COMMUNICATION, USER, SPECIAL_VALUES, SPECIAL_ICONS,
} from '@/settings/Replicated';
import { customerRoles } from '@/settings/Roles';
import { Store } from '@/translations';
import EventBus from '@/util/eventBus';

export default {
	name: 'ReplicatedSiteNav',
	messages: [Store],
	components: { AppHeader },
	mixins: [WindowSizes],
	props: {
		replicated: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			socialNetwork: SOCIAL_NETWORK,
			specialIcons: SPECIAL_ICONS,
			register: true,
			customerRoles,
			openLiveChat: URIS.MAXIMIZE_CHAT,
			themeName: process.env.VUE_APP_THEME,
		};
	},
	computed: {
		navbarClass() {
			if (this.windowWidth === 'xs') {
				return 'mx-auto';
			}

			if (this.dataKeys.length) {
				return '';
			}
			return '';
		},
		defaultProfilePhoto() {
			return require('@/assets/images/common/profile.png'); // eslint-disable-line global-require
		},
		dataKeys() {
			return Object.keys(this.replicated).filter((item) => {
				if (SOCIAL_NETWORK.includes(item)) {
					return this.replicated[item];
				}

				if (COMMUNICATION.includes(item)) {
					return this.replicated[item];
				}
				return null;
			});
		},
		contactKeys() {
			const response = Object.keys(this.replicated).filter((item) => {
				if (COMMUNICATION.includes(item)) {
					return this.replicated[item];
				}
				return null;
			});
			if (this.register) {
				response.push('register');
			}
			return response;
		},
		socialKeys() {
			return Object.keys(this.replicated).filter((item) => {
				if (SOCIAL_NETWORK.includes(item)) {
					return this.replicated[item];
				}
				return null;
			});
		},
		hasSocial() {
			let has = false;
			Object.keys(this.replicated).forEach((item) => {
				if (SOCIAL_NETWORK.includes(item)) {
					if (this.replicated[item][0].id !== '' && this.replicated[item][0].value.length) {
						has = true;
					}
				}
			});
			return has;
		},
		personalKeys() {
			return Object.keys(this.replicated).filter((item) => {
				if (USER.includes(item)) {
					return this.replicated[item];
				}
				return null;
			});
		},
		isCustomerOrAffiliate() {
			return customerRoles.includes(this.replicated.user.type);
		},
		canRegisterWithThisSponsor() {
			return this.replicated.user.can_register;
		},
	},
	mounted() {
		EventBus.$on('closeReplicatedSite', () => this.exitReplicated());
	},
	methods: {
		getItem(key) {
			if (key === 'register') {
				return this.register;
			}

			if (this.personalKeys.includes(key)) {
				return this.replicated[key];
			}

			if (this.contactKeys.includes(key)) {
				const [response] = this.replicated[key];
				if (typeof response !== 'undefined') {
					return response.value;
				}
			}

			if (this.socialKeys.includes(key)) {
				const [response] = this.replicated[key];
				if (typeof response !== 'undefined') {
					return response.value;
				}
			}

			return false;
		},

		getSpecialValue(key) {
			const value = this.getItem(key);
			if (!value) {
				return value;
			}
			if (Object.keys(SPECIAL_VALUES).includes(key)) {
				return SPECIAL_VALUES[key](value);
			}
			return value;
		},
		mailTo(key) {
			location.href = this.getSpecialValue(key); // eslint-disable-line no-restricted-globals
		},
		exitReplicated() {
			const replicatedPage = this.$replicated.replicatedPage();
			const customCartReplicated = replicatedPage === 'checkout';
			// If replicated page is checkout or no discounts store, delete the custom cart id to create a new guest cart.
			if (customCartReplicated) {
				this.$cookie.delete('cart_id');
				localStorage.removeItem('custom_cart_uuid');
			}
			this.$store.dispatch('replicated/siteReplicatedSponsorOff');
			this.$store.dispatch('replicated/siteOff');
			this.$store.dispatch('replicated/siteReplicatedPageOff');
			this.$router.go();
		},
	},
};
</script>

<style>
	.biography-item {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		overflow: hidden;
	}

	.ellipsis {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
	#theme .replicated-navbar {
		height: 70px;
		position: absolute;
		border: 0;
	}
</style>
<style scoped>
	.fab, .fa, .fas {
		font-size: 20px;
	}
</style>
